import {
  Dialog,
  InputAdornment,
  OutlinedInput,
  Stack,
  styled,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';

import { useUserProfileQuery } from '@/api/userProfile';
import Icon from '@/components/Icon';

import LoadingSkeleton from './LoadingSkeleton';
import SearchResultMenu from './SearchResultMenu';
import SearchTips from './SearchTips';

interface SelectedProfileInfo {
  id: string;
  conversationId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

const SEARCH_PAGE_LIMIT = 10;

const StyledDialog = styled(Dialog)({
  '.MuiBackdrop-root': {
    background: 'transparent',
  },
  '& .MuiDialog-paper': {
    borderRadius: '8px',
    boxShadow: '0px 16px 48px 0px rgba(0, 0, 0, 0.16)',
    position: 'fixed',
    top: '34px',
    overflowX: 'hidden',
  },
});

const GlobalContactSearchModal = ({
  onClose,
  isOpened = false,
}: {
  onClose: () => void;
  isOpened?: boolean;
}) => {
  const { t } = useTranslation();
  const searchRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState('');
  // debouncedVal will be used as api param
  const [debouncedVal, setDebouncedVal] = useState(search);

  const { data, isLoading } = useUserProfileQuery<{
    userProfiles: SelectedProfileInfo[];
    totalResult: number;
  }>({
    limit: SEARCH_PAGE_LIMIT,
    offset: 0,
    data: [
      {
        fieldName: 'displayname',
        conditionOperator: 'Contains',
        values: [debouncedVal],
        nextOperator: 'Or',
      },
      {
        fieldName: 'firstName',
        conditionOperator: 'Contains',
        values: [debouncedVal],
        nextOperator: 'Or',
      },
      {
        fieldName: 'phonenumber',
        conditionOperator: 'Contains',
        values: [debouncedVal],
        nextOperator: 'Or',
      },
      {
        fieldName: 'email',
        conditionOperator: 'Contains',
        values: [debouncedVal],
        nextOperator: 'Or',
      },
      {
        fieldName: 'lastName',
        conditionOperator: 'Contains',
        values: [debouncedVal],
        nextOperator: 'And',
      },
    ],
    enabled: !!debouncedVal,
    select: (data) => ({
      userProfiles: data.userProfiles.map((profile) => ({
        id: profile.id,
        conversationId: profile.conversationId || '',
        firstName: profile.firstName,
        lastName: profile.lastName,
        phoneNumber: profile.smsUser?.id || '',
        email: profile.emailAddress?.email,
      })),
      totalResult: data.totalResult,
    }),
  });

  const handleClose = () => {
    onClose();
  };

  useDebounce(
    () => {
      if (!search) {
        setDebouncedVal('');
      } else {
        setDebouncedVal(search);
      }
    },
    500,
    [search],
  );

  const handleSearching = (value: string) => {
    setSearch(value);
  };

  return (
    <StyledDialog open={isOpened} onClose={handleClose}>
      <Stack sx={{ width: '50vw', p: '16px' }}>
        <OutlinedInput
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          inputRef={searchRef}
          value={search}
          placeholder={t('inbox.universal-search.placeholder')}
          onChange={(e) => {
            handleSearching(e.target.value);
          }}
          sx={{
            width: '540px',
            height: '20px',
            borderColor: 'transparent',
            ':hover': {
              borderColor: 'transparent',
            },
            '&.Mui-focused': {
              borderColor: 'transparent',
              'fieldset.MuiOutlinedInput-notchedOutline': {
                boxShadow: 'none',
                borderColor: 'transparent',
              },
            },
          }}
          startAdornment={
            <InputAdornment position="start">
              <Icon icon="search" size={20} sx={{ color: 'gray.80' }} />
            </InputAdornment>
          }
        />
      </Stack>
      {!debouncedVal ? (
        <SearchTips />
      ) : isLoading ? (
        <LoadingSkeleton />
      ) : (
        <SearchResultMenu
          keyword={debouncedVal}
          data={data?.userProfiles ?? ([] as SelectedProfileInfo[])}
          onCloseModal={onClose}
        />
      )}
    </StyledDialog>
  );
};

export default GlobalContactSearchModal;
