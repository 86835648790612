import { Box, InputBase } from '@mui/material';
import {
  createTheme,
  Shadows,
  TypographyVariantsOptions,
} from '@mui/material/styles';

import Icon from '@/components/Icon';

// Global font settings
const BASE_FONT_FAMILY = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];
const HEADER_FONT_FAMILY = ['Matter', ...BASE_FONT_FAMILY].join(',');
const BODY_FONT_FAMILY = ['Inter', ...BASE_FONT_FAMILY].join(',');

// Color palette definitions UIKIT: https://www.figma.com/file/oFWSuE8RLD7V5dprO2P4Y6/UI-Kit?node-id=2%3A3&t=0md3Vm45FLaI2RDs-0
export const colors = {
  // WHITE
  transparent: 'transparent',
  white: '#ffffff',
  // BLUE
  blue5: '#FBFCFF',
  blue10: '#F2F7FF',
  blue20: '#E1EDFF',
  blue30: '#CCE0FF',
  blue70: '#4B9BE8',
  blue80: '#4C94FF',
  blue90: '#0066ff',
  darkBlue40: '#CCD3DE',
  darkBlue50: '#6F8EBB',
  darkBlue60: '#788BA8',
  darkBlue70: '#576E93',
  darkBlue80: '#202644',
  darkBlue90: '#0D122C',
  // GREY
  grey5: '#F4F6F9',
  grey10: '#F0F3F8',
  grey20: '#EFF2F7',
  grey30: '#E0E6F0',
  grey70: '#BFC1CC',
  grey80: '#9CA6B7',
  grey90: '#6A6E81',
  // TURQUOISE
  turquoise5: '#E4F5FF',
  turquoise90: '#58C2E8',
  // PURPLE
  purple5: '#F2F1FE',
  purple10: '#EBEAFD',
  purple20: '#DCD9FC',
  purple80: '#797EF6',
  purple90: '#60559A ',
  // ORANGE
  orange5: '#FFFCF4',
  orange10: '#FFF9EB',
  orange20: '#FFF6E0',
  orange30: '#EBC47A',
  orange80: '#E4B14E',
  orange90: '#D79921',
  // MUSTARD
  mustard5: '#F9F0D0',
  mustard50: '#EED13B',
  mustard90: '#BD9B1D',
  // RED
  red5: '#FFF4F4',
  red10: '#FFE4E4',
  red20: '#FDDEDE',
  red30: '#F8A0A0',
  red60: '#F57777',
  red70: '#E84B4B',
  red80: '#D14747',
  red90: '#CD1212',
  // FOREST
  forest5: '#EBFFEE',
  forest10: '#DBFFE1',
  forest20: '#DFFFE5',
  forest30: '#B9EDC2',
  forest60: '#15C433',
  forest70: '#53A55D',
  forest80: '#39A86C',
  forest90: '#13972A',
  // EMERALD
  emerald90: '#6FBBB8',
  // BROWN
  brown10: '#FDECE4',
  brown90: '#A17866',
};

export const semanticTokens = {
  bgEnabled: colors.white,
  bgDisabled: colors.grey20,
  bgError: colors.red5,
  borderEnabled: colors.grey30,
  borderHover: colors.grey70,
  borderSelected: colors.blue90,
  borderAccent: colors.blue90,
  borderError: colors.red90,
  borderDisabled: colors.transparent,
  contentPrimary: colors.darkBlue90,
  contentSecondary: colors.grey90,
  contentTertiary: colors.grey80,
  contentPlaceholder: colors.grey70,
  contentInverted: colors.white,
  contentAccent: colors.blue90,
  contentSuccess: colors.forest90,
  contentWarning: colors.orange90,
  contentError: colors.red90,
  iconBgDefault: colors.white,
  iconBgHover: colors.grey10,
  iconBgDisabled: colors.transparent,
  iconBgError: colors.transparent,
  iconBorderEnabled: colors.darkBlue70,
  iconBorderHover: colors.darkBlue70,
  iconBorderDisabled: colors.darkBlue40,
  iconBorderError: colors.red90,
  iconBorderInverted: colors.white,
  textPrimary: colors.darkBlue90,
  textSecondary: colors.grey90,
  textTertiary: colors.grey80,
  textPlaceholder: colors.grey70,
  textSuccess: colors.forest90,
  textWarning: colors.orange90,
  textError: colors.red90,
  textInverted: colors.white,
  textHighlight: colors.blue90,
};

export const basePalette = {
  divider: colors.grey30,
  primary: {
    main: colors.darkBlue90,
  },
  white: colors.white,
  transparent: colors.transparent,
  blue: {
    contrastText: colors.blue70,
    5: colors.blue5,
    10: colors.blue10,
    20: colors.blue20,
    30: colors.blue30,
    70: colors.blue70,
    80: colors.blue80,
    90: colors.blue90,
  },
  darkBlue: {
    light: colors.blue90,
    contrastText: colors.blue90,
    40: colors.darkBlue40,
    50: colors.darkBlue50,
    60: colors.darkBlue60,
    70: colors.darkBlue70,
    80: colors.darkBlue80,
    90: colors.darkBlue90,
  },
  // Use gray instead of grey because grey is typed by mui internally
  gray: {
    contrastText: colors.grey90,
    5: colors.grey5,
    10: colors.grey10,
    20: colors.grey20,
    30: colors.grey30,
    70: colors.grey70,
    80: colors.grey80,
    90: colors.grey90,
  },
  turquoise: {
    5: colors.turquoise5,
    90: colors.turquoise90,
  },
  purple: {
    contrastText: colors.purple90,
    5: colors.purple5,
    10: colors.purple10,
    20: colors.purple20,
    80: colors.purple80,
    90: colors.purple90,
  },
  orange: {
    5: colors.orange5,
    10: colors.orange10,
    20: colors.orange20,
    30: colors.orange30,
    80: colors.orange80,
    90: colors.orange90,
  },
  mustard: {
    contrastText: colors.mustard90,
    5: colors.mustard5,
    50: colors.mustard50,
    90: colors.mustard90,
  },
  red: {
    contrastText: colors.red70,
    5: colors.red5,
    10: colors.red10,
    20: colors.red20,
    30: colors.red30,
    60: colors.red60,
    70: colors.red70,
    80: colors.red80,
    90: colors.red90,
  },
  forest: {
    contrastText: colors.forest70,
    5: colors.forest5,
    10: colors.forest10,
    20: colors.forest20,
    30: colors.forest30,
    60: colors.forest60,
    70: colors.forest70,
    80: colors.forest80,
    90: colors.forest90,
  },
  emerald: {
    90: colors.emerald90,
  },
  brown: {
    contrastText: colors.brown90,
    10: colors.brown10,
    90: colors.brown90,
  },
  indigo: {
    contrastText: colors.darkBlue50,
  },
};

export const componentTokens = {
  button: {
    bgPrimaryEnabled: colors.blue90,
    bgPrimaryHover: colors.blue80,
    bgPrimaryDisabled: colors.blue30,
    bgDestructiveDisabled: colors.red30,
    bgDestructiveHover: colors.red80,
    bgDestructiveEnabled: colors.red90,
    bgTertiaryDisabled: colors.transparent,
    bgTertiaryHover: colors.grey10,
    bgTertiaryEnabled: colors.transparent,
    bgSecondaryDisabled: colors.transparent,
    bgSecondaryHover: colors.blue10,
    bgSecondaryEnabled: colors.transparent,
    borderDesctructiveDisabled: colors.transparent,
    borderDesctructiveHover: colors.transparent,
    borderDesctructiveEnabled: colors.transparent,
    borderTertiaryDisabled: colors.transparent,
    borderTertiaryHover: colors.transparent,
    borderTertiaryEnabled: colors.transparent,
    borderSecondaryDisabled: colors.blue30,
    borderSecondaryHover: colors.blue80,
    borderSecondaryEnabled: colors.blue90,
    borderPrimaryDisabled: colors.transparent,
    borderPrimaryHover: colors.transparent,
    borderPrimaryEnabled: colors.transparent,
    textDestructiveDisabled: colors.red30,
    textDestructiveHover: colors.red80,
    textDestructiveEnabled: colors.red90,
    textTertiaryDisabled: colors.darkBlue40,
    textTertiaryHover: colors.darkBlue60,
    textTertiaryEnabled: colors.darkBlue70,
    textSecondaryDisabled: colors.blue30,
    textSecondaryHover: colors.blue80,
    textSecondaryEnabled: colors.blue90,
    textPrimaryDisabled: colors.white,
    textPrimaryHover: colors.white,
    textPrimaryEnabled: colors.white,
  },
  calendar: {
    bgEnabled: colors.white,
    bgDisabled: colors.white,
    bgSelected: colors.blue90,
    bgRange: colors.blue20,
    bgHover: colors.white,
    bgEmpty: colors.white,
    bgCurrent: colors.white,
    textEnabled: colors.grey90,
    textDisabled: colors.grey70,
    textSelected: colors.white,
    textRange: colors.darkBlue90,
    textHover: colors.blue90,
    textEmpty: colors.white,
    textCurrent: colors.grey90,
  },
  card: {
    borderEnabled: colors.grey30,
    bgDefault: colors.white,
    bgHover: colors.blue5,
    bgSelected: colors.blue5,
  },
  chip: {
    bg: colors.grey5,
    text: colors.grey90,
  },
  date: {
    bgEnabled: semanticTokens.bgEnabled,
    bgHover: semanticTokens.bgEnabled,
    bgDisabled: semanticTokens.bgDisabled,
    bgFocus: semanticTokens.bgEnabled,
    bgSelected: semanticTokens.bgEnabled,
    bgError: semanticTokens.bgError,
    borderEnabled: semanticTokens.borderEnabled,
    borderHover: semanticTokens.borderHover,
    borderDisabled: semanticTokens.borderDisabled,
    borderFocus: semanticTokens.borderAccent,
    borderSelected: semanticTokens.borderEnabled,
    borderError: semanticTokens.borderError,
    hintsEnabled: semanticTokens.textSecondary,
    hintsHover: semanticTokens.textSecondary,
    hintsDisabled: semanticTokens.textSecondary,
    hintsFocus: semanticTokens.textSecondary,
    hintsSelected: semanticTokens.textSecondary,
    hintsError: semanticTokens.textError,
    textEnabled: semanticTokens.textPlaceholder,
    textHover: semanticTokens.textPlaceholder,
    textDisabled: semanticTokens.textPlaceholder,
    textFocus: semanticTokens.textSecondary,
    textSelected: semanticTokens.textSecondary,
    textError: semanticTokens.textError,
    titleEnabled: semanticTokens.textTertiary,
    titleHover: semanticTokens.textTertiary,
    titleDisabled: semanticTokens.textTertiary,
    titleFocus: semanticTokens.textTertiary,
    titleSelected: semanticTokens.textTertiary,
    titleError: semanticTokens.textError,
  },
  dateFilter: {
    bgEnabled: semanticTokens.bgEnabled,
    bgHover: semanticTokens.bgEnabled,
    bgDisabled: semanticTokens.bgDisabled,
    bgFocus: semanticTokens.bgEnabled,
    bgSelected: semanticTokens.bgEnabled,
    bgError: semanticTokens.bgError,
    borderEnabled: semanticTokens.borderEnabled,
    borderHover: semanticTokens.borderHover,
    borderDisabled: semanticTokens.borderDisabled,
    borderFocus: semanticTokens.borderAccent,
    borderSelected: semanticTokens.borderEnabled,
    borderError: semanticTokens.borderError,
    textEnabled: semanticTokens.textPlaceholder,
    textHover: semanticTokens.textPlaceholder,
    textDisabled: semanticTokens.textPlaceholder,
    textFocus: semanticTokens.textSecondary,
    textSelected: semanticTokens.textSecondary,
    textError: semanticTokens.textError,
  },
  timePicker: {
    bgEnabled: semanticTokens.bgEnabled,
    bgHover: semanticTokens.bgEnabled,
    bgDisabled: semanticTokens.bgDisabled,
    bgFocus: semanticTokens.bgEnabled,
    bgSelected: semanticTokens.bgEnabled,
    bgError: semanticTokens.bgError,
    borderEnabled: semanticTokens.borderEnabled,
    borderHover: semanticTokens.borderHover,
    borderDisabled: semanticTokens.borderDisabled,
    borderFocus: semanticTokens.borderAccent,
    borderSelected: semanticTokens.borderEnabled,
    borderError: semanticTokens.borderError,
    hintsEnabled: semanticTokens.textSecondary,
    hintsHover: semanticTokens.textSecondary,
    hintsDisabled: semanticTokens.textSecondary,
    hintsFocus: semanticTokens.textSecondary,
    hintsSelected: semanticTokens.textSecondary,
    hintsError: semanticTokens.textError,
    textEnabled: semanticTokens.textPlaceholder,
    textHover: semanticTokens.textPlaceholder,
    textDisabled: semanticTokens.textPlaceholder,
    textFocus: semanticTokens.textSecondary,
    textSelected: semanticTokens.textSecondary,
    textError: semanticTokens.textError,
    titleEnabled: semanticTokens.textTertiary,
    titleHover: semanticTokens.textTertiary,
    titleDisabled: semanticTokens.textTertiary,
    titleFocus: semanticTokens.textTertiary,
    titleSelected: semanticTokens.textTertiary,
    titleError: semanticTokens.textError,
  },
  timeFilter: {
    bgEnabled: semanticTokens.bgEnabled,
    bgHover: semanticTokens.bgEnabled,
    bgDisabled: semanticTokens.bgDisabled,
    bgFocus: semanticTokens.bgEnabled,
    bgSelected: semanticTokens.bgEnabled,
    bgError: semanticTokens.bgError,
    borderEnabled: semanticTokens.borderEnabled,
    borderHover: semanticTokens.borderHover,
    borderDisabled: semanticTokens.borderDisabled,
    borderFocus: semanticTokens.borderAccent,
    borderSelected: semanticTokens.borderEnabled,
    borderError: semanticTokens.borderError,
    textEnabled: semanticTokens.textPlaceholder,
    textHover: semanticTokens.textPlaceholder,
    textDisabled: semanticTokens.textPlaceholder,
    textFocus: semanticTokens.textSecondary,
    textSelected: semanticTokens.textSecondary,
    textError: semanticTokens.textError,
  },
  dropdown: {
    bgEnabled: semanticTokens.bgEnabled,
    bgHover: semanticTokens.bgEnabled,
    bgDisabled: semanticTokens.bgDisabled,
    bgFocus: semanticTokens.bgEnabled,
    bgSelected: semanticTokens.bgEnabled,
    bgError: semanticTokens.bgError,
    borderEnabled: semanticTokens.borderEnabled,
    borderHover: semanticTokens.borderHover,
    borderDisabled: semanticTokens.borderDisabled,
    borderFocus: semanticTokens.borderAccent,
    borderSelected: semanticTokens.borderEnabled,
    borderError: semanticTokens.borderError,
    hintsEnabled: semanticTokens.textSecondary,
    hintsHover: semanticTokens.textSecondary,
    hintsDisabled: semanticTokens.textSecondary,
    hintsFocus: semanticTokens.textSecondary,
    hintsSelected: semanticTokens.textSecondary,
    hintsError: semanticTokens.textError,
    textEnabled: semanticTokens.textPlaceholder,
    textHover: semanticTokens.textPlaceholder,
    textDisabled: semanticTokens.textPlaceholder,
    textFocus: semanticTokens.textSecondary,
    textSelected: semanticTokens.textSecondary,
    textError: semanticTokens.textError,
    titleEnabled: semanticTokens.textTertiary,
    titleHover: semanticTokens.textTertiary,
    titleDisabled: semanticTokens.textTertiary,
    titleFocus: semanticTokens.textTertiary,
    titleSelected: semanticTokens.textTertiary,
    titleError: semanticTokens.textError,
  },
  menu: {
    bgEnabled: colors.white,
    bgHover: colors.blue10,
    bgDisabled: semanticTokens.bgDisabled,
    bgSelected: colors.white,
    textEnabled: semanticTokens.textSecondary,
    textHover: colors.blue90,
    textDisabled: colors.darkBlue40,
    textSelected: semanticTokens.textSecondary,
    descriptionEnabled: semanticTokens.textTertiary,
    descriptionHover: colors.blue80,
    descriptionDisabled: colors.darkBlue40,
    descriptionSelected: semanticTokens.textTertiary,
  },
  input: {
    bgEnabled: semanticTokens.bgEnabled,
    bgHover: semanticTokens.bgEnabled,
    bgDisabled: semanticTokens.bgDisabled,
    bgFocus: semanticTokens.bgEnabled,
    bgTyped: semanticTokens.bgEnabled,
    bgError: semanticTokens.bgError,
    borderEnabled: semanticTokens.borderEnabled,
    borderHover: semanticTokens.borderHover,
    borderDisabled: semanticTokens.borderDisabled,
    borderFocus: semanticTokens.borderAccent,
    borderTyped: semanticTokens.borderEnabled,
    borderError: semanticTokens.borderError,
    hintsEnabled: semanticTokens.textSecondary,
    hintsHover: semanticTokens.textSecondary,
    hintsDisabled: semanticTokens.textSecondary,
    hintsFocus: semanticTokens.textSecondary,
    hintsTyped: semanticTokens.textSecondary,
    hintsError: semanticTokens.textError,
    textEnabled: semanticTokens.textPlaceholder,
    textHover: semanticTokens.textPlaceholder,
    textDisabled: semanticTokens.textPlaceholder,
    textFocus: semanticTokens.textSecondary,
    textTyped: semanticTokens.textSecondary,
    textError: semanticTokens.textError,
    titleEnabled: semanticTokens.textTertiary,
    titleHover: semanticTokens.textTertiary,
    titleDisabled: semanticTokens.textTertiary,
    titleFocus: semanticTokens.textTertiary,
    titleTyped: semanticTokens.textTertiary,
    titleError: semanticTokens.textError,
  },
  notification: {
    bgNeutral: colors.grey5,
    bgSuccess: colors.forest5,
    bgWarning: colors.orange5,
    bgError: colors.red5,
    textNeutral: colors.darkBlue70,
    textSuccess: colors.forest90,
    textWarning: colors.orange90,
    textError: colors.red90,
  },
  snackbar: {
    bgNeutral: colors.darkBlue70,
    bgSuccess: colors.forest5,
    bgWarning: colors.orange5,
    bgError: colors.red5,
    textNeutral: colors.white,
    textSuccess: colors.forest90,
    textWarning: colors.orange90,
    textError: colors.red90,
  },
  tag: {
    bgGreen: colors.forest20,
    bgRed: colors.red10,
    bgOrange: colors.orange20,
    bgPurple: colors.purple10,
    bgBrown: colors.brown10,
    bgMustard: colors.mustard5,
    bgBlue: colors.turquoise5,
    bgGrey: colors.grey30,
    bgIndigo: colors.blue20,
    bgLightGrey: colors.grey10,
    textGreen: colors.forest90,
    textRed: colors.red90,
    textOrange: colors.orange90,
    textPurple: colors.purple90,
    textBrown: colors.brown90,
    textMustard: colors.mustard90,
    textBlue: colors.blue70,
    textGrey: colors.grey90,
    textIndigo: colors.darkBlue50,
    textLightGrey: colors.grey90,
  },
};

// Global typography definitions
const typography = {
  fontFamily: BODY_FONT_FAMILY,
  display1: {
    fontFamily: HEADER_FONT_FAMILY,
    fontWeight: 600,
    fontSize: '1.75rem',
    lineHeight: '1.875rem',
    color: colors.darkBlue90,
  },
  headline1: {
    fontFamily: HEADER_FONT_FAMILY,
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: '1.625rem',
    color: colors.darkBlue90,
  },
  headline2: {
    fontFamily: HEADER_FONT_FAMILY,
    fontWeight: 600,
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    color: colors.darkBlue90,
  },
  headline3: {
    fontFamily: HEADER_FONT_FAMILY,
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: colors.darkBlue90,
  },
  headline4: {
    fontFamily: HEADER_FONT_FAMILY,
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '1rem',
    color: colors.darkBlue90,
  },
  headline5: {
    fontFamily: HEADER_FONT_FAMILY,
    fontWeight: 600,
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    color: colors.darkBlue90,
  },
  body1: {
    fontFamily: BODY_FONT_FAMILY,
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    color: colors.grey90,
  },
  body2: {
    fontFamily: BODY_FONT_FAMILY,
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: colors.grey90,
  },
  body3: {
    fontFamily: BODY_FONT_FAMILY,
    fontWeight: 400,
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    color: colors.grey90,
  },
  subtitle: {
    fontFamily: BODY_FONT_FAMILY,
    fontWeight: 600,

    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    color: colors.grey80,
  },
  link1: {
    fontFamily: BODY_FONT_FAMILY,
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    color: colors.grey90,
  },
  link2: {
    fontFamily: BODY_FONT_FAMILY,
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: colors.grey90,
  },
  link3: {
    fontFamily: BODY_FONT_FAMILY,
    fontWeight: 400,
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    color: colors.grey90,
  },
  button1: {
    fontFamily: HEADER_FONT_FAMILY,
    fontSize: '1rem',
    lineHeight: '1.125rem',
    fontWeight: 600,
  },
  button2: {
    fontFamily: HEADER_FONT_FAMILY,
    fontSize: '0.875rem',
    lineHeight: '1rem',
    fontWeight: 600,
  },
  button3: {
    fontFamily: HEADER_FONT_FAMILY,
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    fontWeight: 600,
  },
  menu1: {
    fontFamily: HEADER_FONT_FAMILY,
    fontSize: '0.875rem',
    lineHeight: '1rem',
    fontWeight: 600,
  },
  menu2: {
    fontFamily: HEADER_FONT_FAMILY,
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    fontWeight: 600,
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  tag: {
    fontFamily: HEADER_FONT_FAMILY,
    fontWeight: 600,
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
  },
} as TypographyVariantsOptions;

export type Theme = typeof theme;

const theme = createTheme({
  // Shadows definitions
  // Reference: https://stackoverflow.com/questions/65792331/how-to-override-material-ui-shadows-in-the-theme
  shadows: [
    'none',
    '0px 2px 12px 2px hsla(216, 100%, 50%, 0.1)',
    '0px 8px 10px 3px hsla(0, 0%, 93%, 1)',
    '0px 8px 16px 2px hsla(216, 100%, 50%, 0.04)',
    '0px 8px 32px 2px hsla(216, 100%, 50%, 0.1)',
    //secondary menu shadow
    '0px 6px 16px 4px hsla(216, 100%, 50%, 0.1)',
    ...new Array(20).fill('none'),
  ] as Shadows,

  // Colors palette definitions
  palette: {
    ...semanticTokens,
    ...basePalette,
    componentToken: componentTokens,
  },
  // Typography
  typography,
  components: {
    // Global base styles
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Matter';
          font-style: normal;
          font-weight: 600;
          font-display: swap;
          src: local('Matter'), url('/fonts/Matter-SemiBold.woff2') format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 100 900;
          font-display: swap;
          src: local('Inter'), url('/fonts/Inter-var.woff2') format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
      `,
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          headline1: 'h1',
          headline2: 'h2',
          headline3: 'h3',
          headline4: 'h4',
          headline5: 'h5',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& > .MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.8)',
          },
        },
      },
    },
    // Tag styles
    MuiChip: {
      defaultProps: {
        color: 'gray',
        deleteIcon: (
          <Icon
            icon="x-close"
            sx={{
              flexShrink: 0,
            }}
            size={12}
          />
        ),
      },
      styleOverrides: {
        root: {
          ...typography.tag,
          textTransform: 'uppercase',
          height: 'unset',
          padding: '6px 8px',
          ':has(.MuiChip-deleteIcon)': {
            padding: '6px 10px',
          },
          display: 'flex',
          gap: '6px',
        },
        label: {
          padding: 0,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          maxWidth: '148px',
        },
        icon: {
          margin: 0,
        },
        deleteIcon: {
          margin: '0 0px 0 6px',
        },
      },
      variants: [
        {
          props: { color: 'success' },
          style: {
            color: colors.forest90,
            backgroundColor: colors.forest10,
            '& > .MuiChip-deleteIcon': {
              color: colors.forest90,
              ':hover': {
                opacity: 0.6,
                color: colors.forest90,
              },
            },
          },
        },
        {
          props: { color: 'warning' },
          style: {
            color: colors.orange90,
            backgroundColor: colors.orange20,
            '& > .MuiChip-deleteIcon': {
              color: colors.orange90,
              ':hover': {
                opacity: 0.6,
                color: colors.orange90,
              },
            },
          },
        },
        {
          props: { color: 'error' },
          style: {
            color: colors.red90,
            backgroundColor: colors.red5,
            '.MuiChip-deleteIcon': {
              color: colors.red90,
              ':hover': {
                opacity: 0.6,
                color: colors.red90,
              },
            },
          },
        },
        {
          props: { color: 'purple' },
          style: {
            backgroundColor: colors.purple10,
            '.MuiChip-deleteIcon': {
              color: colors.purple90,
              ':hover': {
                opacity: 0.6,
                color: colors.purple90,
              },
            },
          },
        },
        {
          props: { color: 'red' },
          style: {
            backgroundColor: colors.red10,
            '.MuiChip-deleteIcon': {
              color: colors.red70,
              ':hover': {
                opacity: 0.6,
                color: colors.red70,
              },
            },
          },
        },
        {
          props: { color: 'brown' },
          style: {
            backgroundColor: colors.brown10,
            '.MuiChip-deleteIcon': {
              color: colors.brown90,
              ':hover': {
                opacity: 0.6,
                color: colors.brown90,
              },
            },
          },
        },
        {
          props: { color: 'mustard' },
          style: {
            backgroundColor: colors.mustard5,
            '.MuiChip-deleteIcon': {
              color: colors.mustard90,
              ':hover': {
                opacity: 0.6,
                color: colors.mustard90,
              },
            },
          },
        },
        {
          props: { color: 'forest' },
          style: {
            backgroundColor: colors.forest20,
            '.MuiChip-deleteIcon': {
              color: colors.forest70,
              ':hover': {
                opacity: 0.6,
                color: colors.forest70,
              },
            },
          },
        },
        {
          props: { color: 'blue' },
          style: {
            color: colors.blue70,
            backgroundColor: colors.turquoise5,
            '.MuiChip-deleteIcon': {
              color: colors.blue70,
              ':hover': {
                opacity: 0.6,
                color: colors.blue70,
              },
            },
          },
        },
        {
          props: { color: 'gray' },
          style: {
            backgroundColor: colors.grey30,
            '.MuiChip-deleteIcon': {
              color: colors.grey90,
              ':hover': {
                opacity: 0.6,
                color: colors.grey90,
              },
            },
          },
        },
        {
          props: { color: 'indigo' },
          style: {
            backgroundColor: colors.blue30,
            '.MuiChip-deleteIcon': {
              color: colors.darkBlue50,
              ':hover': {
                opacity: 0.6,
                color: colors.darkBlue50,
              },
            },
          },
        },
        {
          props: { color: 'info' },
          style: {
            fontWeight: 400,
            fontFamily: BODY_FONT_FAMILY,
            textTransform: 'none',
            backgroundColor: colors.grey10,
            color: colors.grey90,
            '.MuiChip-deleteIcon': {
              color: colors.darkBlue90,
              ':hover': {
                opacity: 0.6,
                color: colors.darkBlue50,
              },
            },
          },
        },
      ],
    },
    // Input Label Style
    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...typography.subtitle,
          transform: 'none',
          textTransform: 'uppercase',
          position: 'static',
          marginBottom: '8px',
          borderColor: colors.grey30,
          '&.Mui-focused': {
            color: colors.grey80,
          },
        },
      },
    },
    // Outlined Input Styles
    MuiOutlinedInput: {
      styleOverrides: {
        multiline: {
          // Allow scrollbar to appear on the root without any spacing offset
          padding: 0,
          '.MuiOutlinedInput-input': {
            padding: '16px 14px',
          },
        },
      },
    },
    // Input styles
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...typography.body1,
          height: '40px',
          width: '100%',
          borderColor: colors.grey30,
          '&.Mui-error': {
            borderColor: colors.red90,
            backgroundColor: colors.red5,
            color: colors.red90,
          },
          '.MuiInputBase-root': {
            paddingLeft: '8px',
            paddingRight: '8px',
          },
          'fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: 'inherit',
          },
          '&.Mui-disabled': {
            'fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: 'inherit',
            },
            '&:hover': {
              borderColor: colors.grey20,
            },
            borderColor: colors.grey20,
            backgroundColor: colors.grey20,
          },
          '&:hover': {
            borderColor: colors.grey70,
            'fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: 'inherit',
            },
          },
          '&.Mui-focused': {
            borderColor: colors.blue90,
            'fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: 'inherit',
              boxShadow: '0px 0px 10px 3px rgba(0, 102, 255, 0.12)',
              borderWidth: '1px',
            },
          },
          '&.MuiInputBase-adornedStart': {
            '.MuiInputBase-input': {
              paddingLeft: '8px',
              paddingRight: '8px',
            },
          },
        },
      },
      variants: [
        {
          props: { multiline: true },
          style: {
            height: '100%',
          },
        },
      ],
    },
    //Form helper text style
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...typography.body2,
          margin: '8px 0',

          '&.Mui-disabled': {
            ...typography.body2,
          },
        },
      },
    },
    //Form Label style
    MuiFormLabel: {
      styleOverrides: {
        root: {
          ...typography.subtitle,
          margin: '8px 0',
          textTransform: 'uppercase',
          '&.Mui-disabled': {
            ...typography.subtitle,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
          width: '100%',
          '& input[type=number]': {
            appearance: 'textfield',
          },
          '& input[type=number]::-webkit-outer-spin-button': {
            appearance: 'none',
            margin: 0,
          },
          '& input[type=number]::-webkit-inner-spin-button': {
            appearance: 'none',
            margin: 0,
          },
        },
      },
    },
    // Button styles
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'sm',
      },
      styleOverrides: {
        root: {
          fontFamily: HEADER_FONT_FAMILY,
          fontWeight: 600,
          textTransform: 'none',
          letterSpacing: 0,
          lineHeight: 1,
        },
      },
      variants: [
        {
          props: { size: 'lg' },
          style: {
            padding: '11px 20px',
            fontSize: '1rem',
          },
        },
        {
          props: { size: 'sm' },
          style: {
            padding: '11px 20px',
            ...typography.button2,
          },
        },
        {
          props: { size: 'lg' },
          style: {
            padding: '11px 20px',
            ...typography.button1,
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            boxShadow: 'none',
            color: colors.white,
            backgroundColor: colors.blue90,
            ':hover': {
              boxShadow: 'none',
              backgroundColor: colors.blue80,
            },
            '&.Mui-disabled': {
              color: colors.white,
              backgroundColor: colors.blue30,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            boxShadow: 'none',
            color: colors.white,
            backgroundColor: colors.grey90,
            ':hover': {
              boxShadow: 'none',
              backgroundColor: colors.grey80,
            },
            '&.Mui-disabled': {
              color: colors.white,
              backgroundColor: colors.grey30,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'error' },
          style: {
            boxShadow: 'none',
            color: colors.white,
            backgroundColor: colors.red90,
            ':hover': {
              boxShadow: 'none',
              backgroundColor: colors.red80,
            },
            '&.Mui-disabled': {
              color: colors.white,
              backgroundColor: colors.red30,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'warning' },
          style: {
            boxShadow: 'none',
            color: colors.white,
            backgroundColor: colors.orange90,
            ':hover': {
              boxShadow: 'none',
              backgroundColor: colors.orange80,
            },
            '&.Mui-disabled': {
              color: colors.white,
              backgroundColor: colors.orange30,
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            color: colors.blue90,
            border: `1px solid ${colors.blue90}`,
            ':hover': {
              color: colors.blue80,
              backgroundColor: colors.blue20,
              border: `1px solid ${colors.blue80}`,
            },
            '&.Mui-disabled': {
              color: colors.blue30,
              borderColor: colors.blue30,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'error' },
          style: {
            color: colors.red90,
            border: `1px solid ${colors.red90}`,
            ':hover': {
              color: colors.red80,
              backgroundColor: colors.red20,
              border: `1px solid ${colors.red80}`,
            },
            '&.Mui-disabled': {
              color: colors.red30,
              borderColor: colors.red30,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'warning' },
          style: {
            color: colors.orange90,
            border: `1px solid ${colors.orange90}`,
            ':hover': {
              color: colors.orange80,
              backgroundColor: colors.orange20,
              border: `1px solid ${colors.orange80}`,
            },
            '&.Mui-disabled': {
              color: colors.orange30,
              borderColor: colors.orange30,
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            color: colors.darkBlue70,
            ':hover': {
              background: colors.grey10,
            },
            '&.Mui-disabled': {
              backgroundColor: 'transparent',
              cursor: 'not-allowed',
              pointerEvents: 'initial',
            },
          },
        },
        {
          props: { variant: 'text', color: 'error' },
          style: {
            color: colors.darkBlue70, // intend to keep darkBlue 70 before hover
            ':hover': {
              backgroundColor: colors.red5,
              color: colors.red90,
            },
            '&.Mui-disabled': {
              backgroundColor: 'transparent',
              cursor: 'not-allowed',
              pointerEvents: 'initial',
            },
          },
        },
        {
          props: { variant: 'text', size: 'sm' },
          style: {
            padding: '8px 12px',
          },
        },
      ],
    },
    // Checkbox styles
    MuiCheckbox: {
      variants: [
        {
          props: { size: 'small' },
          style: {
            width: 16,
            height: 16,
          },
        },
        {
          props: { size: 'medium' },
          style: {
            width: 20,
            height: 20,
          },
        },
      ],
      defaultProps: {
        size: 'medium',
        indeterminateIcon: (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              background: colors.grey80,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
              border: `1px solid ${colors.grey80}`,
            }}
          >
            <Icon icon={'minus'} sx={{ color: 'white' }} size={12} />
          </Box>
        ),
        icon: (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              background: colors.white,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
              border: `1px solid ${colors.grey30}`,
            }}
          />
        ),
        checkedIcon: (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              background: colors.blue90,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
              border: `1px solid ${colors.blue90}`,
            }}
          >
            <Icon icon={'check-single'} sx={{ color: 'white' }} size={12} />
          </Box>
        ),
      },
      styleOverrides: {
        root: {
          padding: '2px',
          '& > .MuiSvgIcon-root': {
            fill: colors.grey30,
          },
          ':hover': {
            '& > .MuiSvgIcon-root': {
              fill: colors.blue90,
            },
          },
          '&.MuiCheckbox-indeterminate > .MuiSvgIcon-root': {
            fill: colors.grey80,
          },
          '&.Mui-checked > .MuiSvgIcon-root': {
            fill: colors.blue90,
          },
          '&.Mui-checked:hover': {
            borderTopRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderBottomLeftRadius: '5px',
            padding: '2px',
            backgroundColor: 'rgba(0, 102, 255, 0.1)',
          },
        },
      },
    },
    // Switch styles
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '26px',
          height: '16px',
          padding: 0,
          display: 'flex',
        },
        switchBase: {
          padding: 2,
          '&.Mui-checked.Mui-disabled': {
            color: '#fff',
          },
          '&.Mui-checked.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: colors.forest30,
          },
          '&.Mui-checked': {
            transform: 'translateX(10px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: colors.forest90,
            },
          },
        },
        thumb: {
          boxShadow: 'none',
          width: 12,
          height: 12,
          borderRadius: 6,
        },
        track: {
          borderRadius: 16 / 2,
          opacity: 1,
          backgroundColor: colors.grey30,
          boxSizing: 'border-box',
        },
      },
    },
    // Menu styles
    MuiMenu: {
      styleOverrides: {
        root: {
          '& > .MuiPaper-root': {
            boxShadow: '0px 8px 32px 2px rgba(0, 102, 255, 0.1)',
          },
        },
        list: {
          padding: '12px 0 4px 0',
          minWidth: '120px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...typography.body1,
          backgroundColor: colors.white,
          "&[aria-selected='true']": {
            backgroundColor: colors.blue5,
            color: colors.blue90,
            '&:hover': {
              backgroundColor: colors.blue10,
              color: colors.blue90,
            },
          },
          '&.Mui-selected': {
            backgroundColor: colors.blue5,
            color: colors.blue90,
            '&:hover': {
              backgroundColor: colors.blue10,
              color: colors.blue90,
            },
          },
          '&.MuiButtonBase-root.MuiMenuItem-root.Mui-focusVisible': {
            backgroundColor: `${colors.blue10}`,
            color: colors.blue90,
            '&:hover': {
              backgroundColor: colors.blue10,
              color: colors.blue90,
            },
          },
          '&:hover': {
            color: colors.blue90,
            backgroundColor: colors.blue10,
          },
          '&:focus': {
            color: colors.blue90,
            backgroundColor: colors.blue5,
          },

          padding: '8px 12px',
          minHeight: 'unset',
          marginBottom: '8px',
        },
      },
    },
    // Icon button styles
    MuiIconButton: {
      defaultProps: {
        size: 'md',
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled.MuiIconButton-root': {
            color: colors.darkBlue40,
            '&:hover': {
              backgroundColor: 'transparent',
            },
            pointerEvents: 'auto', // ref: https://stackoverflow.com/questions/61115913/is-it-possible-to-render-a-tooltip-on-a-disabled-mui-button-within-a-buttongroup
          },

          '&:not(.MuiIconButton-colorError)': {
            color: colors.darkBlue70,
            '&:hover': {
              backgroundColor: colors.grey20,
            },
            '&.Mui-focusVisible': {
              backgroundColor: colors.grey20,
            },
          },
        },
        colorPrimary: {
          backgroundColor: colors.blue20,
          color: colors.blue70,
        },
      },
      variants: [
        {
          props: { size: 'xs' },
          style: {
            padding: '2px',
            height: '16px',
            width: '16px',
            borderRadius: '4px',
          },
        },
        {
          props: { size: 'sm' },
          style: {
            padding: '4px',
            height: '24px',
            width: '24px',
            borderRadius: '6px',
          },
        },
        {
          props: { size: 'md' },
          style: {
            padding: '4px',
            height: '28px',
            width: '28px',
            borderRadius: '8px',
          },
        },
        {
          props: { color: 'warning' },
          style: {
            ':hover': {
              backgroundColor: colors.red5,
              svg: {
                color: colors.red90,
              },
            },
          },
        },
      ],
    },
    // List item text styles
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    // Table styles
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'unset',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          ...typography.headline4,
          '&:hover': {
            backgroundColor: 'none',
          },
          background: colors.grey20,
        },
      },
    },
    MuiTableRow: {
      variants: [
        {
          props: { selected: true },
          style: {
            fontWeight: 600,
          },
        },
      ],
      styleOverrides: {
        head: {
          '&:hover': {
            backgroundColor: 'unset',
          },
        },
        root: {
          height: '48px',
          '&.Mui-selected': {
            backgroundColor: colors.blue10,
            '&:hover': {
              backgroundColor: colors.blue10,
            },
          },
          '&:hover': {
            backgroundColor: colors.blue10,
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          ...typography.body2,
          color: colors.darkBlue90,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          color: 'inherit',
          borderBottom: `1px solid ${colors.grey10}`,
          fontFamily: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit',
          lineHeight: 'inherit',
          padding: '0px 12px',
          height: '48px',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          '&.MuiLinearProgress-root': {
            background: colors.grey30,
            height: '8px',
            borderRadius: '500px',
          },
          '&.MuiLinearProgress-root .MuiLinearProgress-bar': {
            background: colors.blue90,
            borderRadius: '500px',
          },
        },
      },
    },
    // pagination style
    MuiPagination: {
      defaultProps: {
        shape: 'rounded',
        showFirstButton: true,
        showLastButton: true,
      },
      styleOverrides: {
        root: {
          ...typography.menu2,
          '.MuiPagination-ul': {
            flexWrap: 'nowrap',
          },
          button: {
            '&.MuiPaginationItem-root.Mui-selected': {
              color: 'white',
              background: colors.blue90,
            },
            '&:hover': {
              background: colors.blue5,
              color: colors.blue90,
            },
          },
        },
      },
    },
    MuiPaginationItem: {
      defaultProps: {
        components: {
          first: () => <Icon icon="chevron-left-double" size="16px" />,
          last: () => <Icon icon="chevron-right-double" size="16px" />,
          next: () => <Icon icon="chevron-right" size="16px" />,
          previous: () => <Icon icon="chevron-left" size="16px" />,
        },
      },
      styleOverrides: {
        root: {
          ...typography.menu2,
          color: colors.darkBlue70,
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterDelay: 250,
        enterNextDelay: 250,
      },
      styleOverrides: {
        tooltip: {
          ...typography.body2,
          padding: '12px 16px',
          borderRadius: '4px',
          color: colors.white,
          background: colors.darkBlue70,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          ...typography.menu2,
          padding: '4px',
        },
      },
      variants: [
        {
          props: { color: 'info' },
          style: {
            '& .MuiBadge-badge': {
              backgroundColor: colors.blue90,
            },
          },
        },
        {
          props: { color: 'error' },
          style: {
            '& .MuiBadge-badge': {
              backgroundColor: colors.red80,
            },
          },
        },
      ],
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: colors.grey20,
        },
      },
      variants: [
        {
          props: { variant: 'rectangular' },
          style: {
            borderRadius: '4px',
          },
        },
      ],
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.MuiRadio-root + span': {
            paddingLeft: '8px',
            color: colors.grey70,
          },
          '&.MuiRadio-root.Mui-checked + span': {
            color: colors.darkBlue90,
          },
          color: colors.grey30,
          '&.Mui-checked:not(.Mui-disabled)': {
            backgroundColor: colors.blue5,
            color: colors.blue90,
          },
          '& .MuiSvgIcon-root': {
            fontSize: 20,
          },
          width: '20px',
          height: '20px',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          ...typography.menu2,
        },
      },
      defaultProps: {
        sizes: 'md',
      },
      variants: [
        {
          props: { sizes: 'sm' },
          style: {
            width: '24px',
            height: '24px',
          },
        },
        {
          props: { sizes: 'md' },
          style: {
            width: '32px',
            height: '32px',
          },
        },
        {
          props: { sizes: 'lg' },
          style: {
            width: '40px',
            height: '40px',
          },
        },
      ],
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          marginRight: '8px',
          flexDirection: 'row',
          '& .MuiAvatar-root': {
            borderWidth: '1px',
          },
          '& .MuiAvatar-root:last-child': {
            marginLeft: '-8px',
          },
        },
      },
      defaultProps: {
        slotProps: {
          additionalAvatar: {
            sx: {
              backgroundColor: 'blue.10',
              color: 'blue.90',
              order: 999,
              zIndex: 10,
            },
          },
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        color: 'info',
        slots: {
          closeIcon: () => <Icon icon="x-close" size={20} sx={{ mr: '2px' }} />,
        },
      },
      // Remove default padding for icon, message and action.
      // Centralise the padding on root instead for better spacing control.
      styleOverrides: {
        root: {
          ...typography.body1,
          borderRadius: '8px',
          padding: '16px 20px',
        },
        icon: {
          display: 'none',
        },
        message: {
          padding: 0,
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
        },
        action: {
          padding: 0,
          marginLeft: '30px',
          marginRight: 0,
          alignItems: 'center',
          '& > .MuiButtonBase-root': {
            ...typography.button2,
            padding: 0,
            color: colors.white,
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '& > svg': {
              display: 'none',
              marginRight: '-4px',
            },
          },
        },
      },
      variants: [
        {
          props: { color: 'info' },
          style: {
            backgroundColor: colors.darkBlue70,
            color: colors.white,
            '& > .MuiAlert-action': {
              '.MuiButtonBase-root': {
                color: '#fff',
              },
            },
          },
        },
        {
          props: { color: 'error' },
          style: {
            backgroundColor: colors.red5,
            color: colors.red90,
            '& > .MuiAlert-action': {
              svg: {
                color: colors.red90,
              },
            },
          },
        },
        {
          props: { color: 'success' },
          style: {
            backgroundColor: colors.forest5,
            color: colors.forest90,
            '& > .MuiAlert-action': {
              svg: {
                color: colors.forest90,
              },
            },
          },
        },
        {
          props: { color: 'warning' },
          style: {
            backgroundColor: colors.orange20,
            color: colors.orange90,
            '& > .MuiAlert-action': {
              svg: {
                color: colors.orange90,
              },
            },
          },
        },
      ],
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colors.blue90,
        },
      },
      variants: [
        {
          props: { indicatorColor: 'orange' },
          style: {
            '& .MuiButtonBase-root.MuiTab-root.Mui-selected': {
              color: colors.orange90,
            },
            '& .MuiTabs-indicator': {
              backgroundColor: colors.orange90,
            },
          },
        },
      ],
    },
    MuiTab: {
      styleOverrides: {
        root: {
          ...typography.headline4,
          fontFamily: BODY_FONT_FAMILY,
          textTransform: 'none',
          color: colors.darkBlue70,
          padding: '24px 12px',
          '&.Mui-selected': {
            color: colors.blue90,
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.blue10,
          padding: '20px',
        },
      },
      defaultProps: {
        connector: <Icon icon="chevron-right" size={20} />,
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
        label: {
          ...typography.headline4,
          color: colors.grey70,
          '&.Mui-active': {
            fontWeight: 600,
          },
        },
        iconContainer: {
          svg: {
            color: 'transparent',
            text: {
              fill: colors.grey70,
              fontWeight: 600,
            },
            border: `1px solid ${colors.grey70}`,
            borderRadius: '50%',
            '&.Mui-completed': {
              color: colors.grey70,
              border: 'none',
            },
            '&.Mui-active': {
              color: colors.blue90,
              border: 'none',
              text: {
                fill: colors.white,
              },
            },
          },
        },
      },
      variants: [
        {
          props: { color: 'error' },
          style: {
            '.MuiStepLabel-iconContainer': {
              svg: {
                '&.Mui-active': {
                  color: colors.red90,
                },
                ':not(&.Mui-active)': {
                  border: `1px solid ${colors.red90}`,
                  text: {
                    fill: colors.red90,
                  },
                  '&.Mui-completed': {
                    border: 'none',
                  },
                },
              },
            },
          },
        },
      ],
    },
    // NativeSelect Style
    MuiNativeSelect: {
      defaultProps: {
        input: <InputBase />,
        IconComponent: () =>
          Icon({
            icon: 'chevron-down',
            size: 16,
            sx: { color: colors.grey90, flex: 'none' },
          }),
        // Use defaultProps in this case because styles applied to the styleOverrides root are not being applied.
        sx: {
          border: `1px solid ${colors.grey30}`,
          borderRadius: '4px',
          padding: '10px 12px',
          '&.Mui-focused': {
            borderColor: colors.blue90,
            boxShadow: '0px 0px 10px 3px rgba(0, 102, 255, 0.12)',
            borderWidth: '1px',
          },
        },
      },
      styleOverrides: {
        select: {
          ':focus': {
            backgroundColor: '#fff',
          },
        },
      },
    },
    //Select Style
    MuiSelect: {
      defaultProps: {
        IconComponent: (props) =>
          Icon({
            icon: 'chevron-down',
            size: 16,
            ...props,
          }),
        MenuProps: {
          sx: {
            '&.MuiMenu-root > .MuiPaper-root': {
              maxHeight: '420px',
              minWidth: 'max-content',
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        },
      },
      variants: [
        {
          props: { variant: 'standard' },
          style: {
            '&.MuiInputBase-root > .MuiSelect-select': {
              ...typography.ellipsis,
              lineHeight: 'initial',
              display: 'flex',
              alignItems: 'flex-end',
            },
          },
        },
        {
          props: {
            variant: 'outlined',
          },
          style: {
            paddingRight: '14px',
          },
        },
      ],
      styleOverrides: {
        select: {
          color: colors.grey90,
          background: 'transparent',
        },
        icon: {
          right: 12,
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: (
          <Icon icon="chevron-down" size={20} sx={{ color: colors.grey90 }} />
        ),
      },
      styleOverrides: {
        input: {
          '&.MuiOutlinedInput-input': {
            padding: '0px 9px',
          },
        },
        paper: {
          paddingBottom: 0,
          overflowX: 'hidden',
          marginTop: '5px',
          width: 'max-content',
          minWidth: '240px',
          '& > .MuiAutocomplete-listbox': {
            width: 'max-content',
            minWidth: '240px',
            paddingBottom: 0,
          },
          '& > .MuiAutocomplete-listbox li': {
            backgroundColor: colors.white,
            minWidth: 'max-content',
            ':hover': {
              backgroundColor: colors.blue5,
              color: colors.blue90,
            },
          },
          '& > .MuiAutocomplete-listbox li[aria-selected="true"]': {
            backgroundColor: `${colors.blue5} !important`,
            color: colors.blue90,
            minWidth: 'max-content',
            ':hover': {
              backgroundColor: colors.blue5,
              color: colors.blue90,
            },
          },
          '& > .MuiAutocomplete-listbox li.Mui-focused': {
            backgroundColor: colors.blue5,
            color: colors.blue90,
            minWidth: 'max-content',
            ':hover': {
              backgroundColor: colors.blue5,
              color: colors.blue90,
            },
          },
          '& > .MuiAutocomplete-listbox li.Mui-focusVisible': {
            backgroundColor: `${colors.blue5} !important`,
            color: colors.blue90,
            minWidth: 'max-content',
            ':hover': {
              backgroundColor: colors.blue5,
              color: colors.blue90,
            },
          },
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        size: 18,
      },
      styleOverrides: {
        colorPrimary: {
          color: colors.blue70,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
  },
  flowBuilder: {
    start: {
      primaryColor: colors.blue90,
      icon: 'play',
    },
    action: {
      primaryColor: colors.blue70,
      secondaryColor: colors.blue10,
      icon: 'zap',
    },
    condition: {
      primaryColor: colors.orange80,
      secondaryColor: colors.orange10,
      icon: 'dataflow',
    },
    timeDelay: {
      primaryColor: colors.purple80,
      secondaryColor: colors.purple10,
      icon: 'schedule',
    },
    placeholder: {
      primaryColor: colors.grey80,
      icon: 'rocket',
    },
    end: {
      primaryColor: colors.darkBlue90,
      icon: 'flag',
    },
    // edge
    addEdge: {
      primaryColor: colors.brown90,
      secondaryColor: colors.brown10,
      icon: 'arrow-right',
    },
  },
  //breakpoint styles
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1366,
      xl: 1920,
    },
  },
  // table sizes
  table: {
    columnSizes: {
      xxs: 44,
      xs: 96,
      sm: 120,
      md: 160,
      lg: 200,
      xl: 240,
    },
  },
});

export default theme;
