import { Skeleton } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';

import { useGetStaffById } from '@/api/company';
import { Staff } from '@/api/types';
import { getAvatarUrl } from '@/utils/avatar';

import ColoredAvatar from '../Avatar';

const sizeMap = {
  small: 24,
  medium: 32,
  large: 40,
};

function StaffAvatar({
  userId,
  optionalSrc,
  size = 'small',
  sx,
}: {
  userId: string;
  optionalSrc?: string;
  size?: 'small' | 'medium' | 'large' | number; // pixel is the default unit
  sx?: SxProps;
}) {
  const { data, isLoading } = useGetStaffById({
    userId,
    select: (data: Staff[]) => {
      return {
        profilePictureUrl: data[0]?.profilePictureURL,
        name: data[0]?.name,
      };
    },
  });
  const avatarSize = typeof size === 'number' ? size : sizeMap[size];

  if (isLoading) {
    return (
      <Skeleton variant="circular" width={avatarSize} height={avatarSize} />
    );
  }

  return (
    <ColoredAvatar
      sx={[
        { width: `${avatarSize}px`, height: `${avatarSize}px` },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      name={data?.name ?? ''}
      src={
        data?.profilePictureUrl
          ? getAvatarUrl(data.profilePictureUrl)
          : optionalSrc
      }
      imgProps={{
        loading: 'lazy',
        width: avatarSize,
        height: avatarSize,
      }}
    />
  );
}
export default React.memo(StaffAvatar);
