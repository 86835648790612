import { Box, BoxProps, styled } from '@mui/material';
import * as RadixScrollArea from '@radix-ui/react-scroll-area';
import { forwardRef } from 'react';

export interface ScrollAreaProps extends Omit<BoxProps<'div'>, 'ref'> {
  children: React.ReactNode;
  scrollbarSize?: number;
  onDark?: boolean;
  disabledHorizontalScroll?: boolean;
  slotProps?: {
    viewport: RadixScrollArea.ScrollAreaViewportProps &
      BoxProps &
      React.RefAttributes<HTMLDivElement>;
  };
}

const ScrollAreaRoot = styled(RadixScrollArea.Root)({
  overflow: 'hidden',
});

const ScrollBar = styled(RadixScrollArea.Scrollbar, {
  shouldForwardProp: (prop) => prop !== 'scrollbarSize',
})<{ scrollbarSize: number }>(({ scrollbarSize }) => ({
  display: 'flex',
  userSelect: 'none',
  touchAction: 'none',
  boxSizing: 'border-box',
  padding: scrollbarSize / 5,
  '&[data-orientation="vertical"]': {
    width: scrollbarSize,
  },

  '&[data-orientation="horizontal"]': {
    flexDirection: 'column',
    height: scrollbarSize,
  },
}));

const Thumb = styled(RadixScrollArea.Thumb, {
  shouldForwardProp: (prop) => prop !== 'onDark' && prop !== 'scrollbarSize',
})<{
  scrollbarSize: number;
  onDark: boolean;
}>(({ scrollbarSize, theme, onDark }) => ({
  flex: 1,
  backgroundColor: onDark ? 'white' : theme.palette.gray[90],
  opacity: 0.1,
  borderRadius: scrollbarSize,
  position: 'relative',
  zIndex: theme.zIndex.mobileStepper,
  transition: theme.transitions.create('backgroundColor', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shortest,
  }),
  '&:hover': {
    opacity: 0.5,
  },
  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    minWidth: '44px',
    minHeight: '44px',
  },
}));

const ScrollAreaViewport = styled(RadixScrollArea.Viewport)({
  width: '100%',
  height: '100%',
});

const ScrollArea = forwardRef<HTMLDivElement, ScrollAreaProps>(
  (
    {
      children,
      scrollbarSize = 8,
      onDark = false,
      disabledHorizontalScroll = false,
      slotProps = {},
      ...props
    },
    ref,
  ) => {
    const { viewport: viewportProps } = slotProps;
    return (
      <ScrollAreaRoot ref={ref} asChild>
        <Box {...props}>
          <ScrollAreaViewport {...viewportProps}>{children}</ScrollAreaViewport>
          <ScrollBar scrollbarSize={scrollbarSize} orientation="vertical">
            <Thumb {...{ onDark, scrollbarSize }} />
          </ScrollBar>
          {!disabledHorizontalScroll && (
            <ScrollBar scrollbarSize={scrollbarSize} orientation="horizontal">
              <Thumb {...{ onDark, scrollbarSize }} />
            </ScrollBar>
          )}
          <RadixScrollArea.Corner />
        </Box>
      </ScrollAreaRoot>
    );
  },
);

ScrollArea.displayName = 'ScrollArea';

export default ScrollArea;
