import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/id';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-hk';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isToday from 'dayjs/plugin/isToday';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ICU from 'i18next-icu';
import PhraseInContextEditorPostProcessor from 'i18next-phrase-in-context-editor-post-processor';
import { initReactI18next } from 'react-i18next';

import { AVAILABLE_LANGUAGES } from '@/constants/i18n';

import { en } from './en';
// Phrase requires `id-id` as the locale code, and Auth0 requires `id` (https://auth0.com/docs/customize/internationalization-and-localization/universal-login-internationalization).
// To satisfy both requirements, we would set `id` as one of the app language while we import from `id-id` that's created by Phrase.
import { id } from './id-id';
import { ptBr } from './pt-BR';
import { zhCn } from './zh-CN';
import { zhHk } from './zh-HK';

dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);

export const icu = new ICU({
  bindI18n: 'languageChanged',
  parseLngForICU: (lng) => {
    return lng === 'en' ? 'en-GB' : lng;
  },
  memoize: true,
});

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(
    new PhraseInContextEditorPostProcessor({
      phraseEnabled: import.meta.env.VITE_PHRASE_ENABLED === 'TRUE',
      projectId: '7b51cc56f4e7d83ff6b305bc106fc076',
      autoLowercase: false,
    }),
  )
  .use(icu)
  .init({
    resources: {
      en,
      'zh-HK': zhHk,
      'zh-CN': zhCn,
      id,
      'pt-BR': ptBr,
    },
    fallbackLng: 'en',
    defaultNS: 'common',
    fallbackNS: 'common',
    supportedLngs: AVAILABLE_LANGUAGES,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
    keySeparator: '.',
    detection: {
      order: ['path', 'localStorage', 'navigator'],
    },
    postProcess: ['phraseInContextEditor'],
  });

export const DAYJS_CONFIGS: Record<string, Partial<ILocale>> = {
  en: {
    formats: {
      L: 'MM/DD/YYYY',
      LL: 'dddd, MMMM D YYYY',
      LLLL: 'dddd, MMMM D, YYYY h:mm A',
      LT: 'h:mm A',
      // @ts-expect-error not sure but docs say have this but types say no
      ll: 'MMM D, YYYY',
      l: 'D MMM',
    },
  },
  'zh-cn': {
    formats: {
      L: 'MM/DD/YYYY',
      LL: 'YYYY年M月D日dddd',
      LLLL: 'YYYY年M月D日ddddAh:mm',
      LT: 'A h:mm',
      // @ts-expect-error not sure but docs say have this but types say no
      ll: 'YYYY年M月D日',
      l: 'YYYY年M月',
    },
  },
  'zh-hk': {
    formats: {
      L: 'MM/DD/YYYY',
      LL: 'YYYY年M月D日dddd',
      LLLL: 'YYYY年M月D日ddddAh:mm',
      LT: 'A h:mm',
      // @ts-expect-error not sure but docs say have this but types say no
      ll: 'YYYY年M月D日',
      l: 'YYYY年M月',
    },
  },
  'pt-br': {
    formats: {
      L: 'MM/DD/YYYY',
      LL: 'dddd, D MMMM YYYY',
      LLLL: 'dddd, D MMMM YYYY, HH:mm',
      LT: 'h:mm A',
      // @ts-expect-error not sure but docs say have this but types say no
      ll: 'D MMMM YYYY',
      l: 'MMMM YYYY',
    },
  },
  id: {
    formats: {
      L: 'MM/DD/YYYY',
      LL: 'dddd, D MMMM YYYY',
      LLLL: 'dddd, D MMMM YYYY, h:mm A',
      LT: 'h:mm A',
      // @ts-expect-error not sure but docs say have this but types say no
      ll: 'D MMMM YYYY',
      l: 'MMMM YYYY',
    },
  },
};

dayjs.locale(i18n.language.toLowerCase());
dayjs.updateLocale('en', DAYJS_CONFIGS['en']);
dayjs.updateLocale('zh-cn', DAYJS_CONFIGS['zh-cn']);
dayjs.updateLocale('zh-hk', DAYJS_CONFIGS['zh-hk']);
dayjs.updateLocale('pt-br', DAYJS_CONFIGS['pt-br']);
dayjs.updateLocale('id', DAYJS_CONFIGS['id']);

const ICU_CONFIGS = {
  'zh-HK': {
    number: {},
  },
  'pt-BR': {
    number: {},
  },
  id: {
    number: {},
  },
  en: {
    number: {},
  },
  'zh-CN': {
    number: {},
  },
} as const;

export const getICUConfig = (lng: string) => {
  return (
    ICU_CONFIGS[lng as keyof typeof ICU_CONFIGS] ||
    ICU_CONFIGS['en' as keyof typeof ICU_CONFIGS]
  );
};

icu.addUserDefinedFormats(getICUConfig(i18n.language));

export default i18n;
