import { Box, Button, Stack, Typography } from '@mui/material';
import PageTitle from '@/components/PageTitle';
import Icon from '@/components/Icon';
import type { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

const PageHeaderWithBackButton = ({
  title,
  subtitle,
  action,
  onBackButtonClicked,
}: {
  title: ReactNode;
  subtitle?: string;
  action?: ReactNode;
  onBackButtonClicked?: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <Stack direction="row">
      <Button
        sx={{
          borderRadius: 0,
          borderBottom: `1px solid`,
          borderRight: `1px solid`,
          borderColor: 'gray.30',
        }}
        onClick={() =>
          onBackButtonClicked ? onBackButtonClicked() : navigate('..')
        }
      >
        <Icon icon="arrow-left" size={16} />
      </Button>
      <Box width="100%">
        <Stack
          direction="row"
          sx={(theme) => ({
            justifyContent: 'space-between',
            borderBottom: `1px solid ${theme.palette.gray[30]}`,
            alignItems: 'center',
            paddingX: '2rem',
          })}
        >
          <PageTitle
            title={title}
            subtitleComponent={
              subtitle && (
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: '600',
                    textTransform: 'uppercase',
                  }}
                  variant="subtitle"
                >
                  {subtitle}
                </Typography>
              )
            }
          />
          {action}
        </Stack>
      </Box>
    </Stack>
  );
};

export default PageHeaderWithBackButton;
