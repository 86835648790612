import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
  styled,
} from '@mui/material';
import { forwardRef } from 'react';

import { getAvatarBackgroundColorByName, getInitials } from '@/utils/avatar';

export interface AvatarProps extends MuiAvatarProps {
  name: string;
  src?: string;
}

const ColoredAvatar = styled(
  forwardRef<HTMLDivElement, AvatarProps>(function Avatar(
    { name = '', src, ...restProps },
    ref,
  ) {
    const props = { alt: name, ...restProps } as AvatarProps;
    if (src) {
      props.src = src;
    } else {
      props.children = getInitials(name);
    }
    return (
      <MuiAvatar
        ref={ref}
        {...props}
        src={src}
        sx={{
          backgroundColor: getAvatarBackgroundColorByName(name),
          svg: {
            width: '150%',
            height: '150%',
            paddingTop: '30%',
          },
        }}
      />
    );
  }),
)({});

export default ColoredAvatar;
