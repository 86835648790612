import { SubscriptionPlan } from '@/api/types';

export function isFreeOrFreemiumPlan(plan: SubscriptionPlan) {
  return plan.subscriptionTier === 0;
}

export function isProPlan(plan: SubscriptionPlan) {
  return plan.subscriptionTier === 1;
}

export function isEnterprisePlan(plan: SubscriptionPlan) {
  return plan.subscriptionTier === 3;
}

export function isPremiumPlan(plan: SubscriptionPlan) {
  return plan.subscriptionTier === 2;
}

export function isYearlyPlan(plan: SubscriptionPlan) {
  return /yearly/i.test(plan.id);
}
