import { Avatar, styled, Theme } from '@mui/material';
import { MUIStyledCommonProps } from '@mui/system';
import IcoMoon, { IconProps as IcoMoonProps } from 'react-icomoon';

import { IconNames } from '@/assets/icomoon/icon';
import iconSet from '@/assets/icomoon/selection.json';

const cdnUrl = import.meta.env.VITE_CDN_BASE_URL;

const icons = {
  whatsapp: `${cdnUrl}/channels/whatsapp.svg`,
  whatsapp360dialog: `${cdnUrl}/channels/whatsapp.svg`,
  whatsappcloudapi: `${cdnUrl}/channels/whatsapp.svg`,
  twilio_whatsapp: `${cdnUrl}/channels/whatsapp.svg`,
  messenger: `${cdnUrl}/channels/messenger.svg`,
  instagram: `${cdnUrl}/channels/instagram.svg`,
  viber: `${cdnUrl}/channels/viber.svg`,
  line: `${cdnUrl}/channels/line.svg`,
  wechat: `${cdnUrl}/channels/wechat.svg`,
  web: `${cdnUrl}/channels/livechat.svg`,
  sms: `${cdnUrl}/channels/sms.svg`,
  facebook: `${cdnUrl}/channels/facebook.svg`,
  zapier: '',
  sleekflowApi: '',
  googleSheet: '',
  woocommerce: '',
  shopline: '',
  calendly: '',
  make: '',
  facebookLeadAds: `${cdnUrl}/channels/facebook.svg`,
  telegram: `${cdnUrl}/channels/telegram.svg`,
  salesforce: `${cdnUrl}/channels/salesforce.svg`,
  stripe: `${cdnUrl}/channels/stripe.svg`,
  hubspot: `${cdnUrl}/channels/hubspot.svg`,
  shopify: `${cdnUrl}/channels/shopify.svg`,
  'play-circle': `${cdnUrl}/media/play-circle.svg`,
  livechat: `${cdnUrl}/media/live-chat.svg`,
  note: 'notification',
};

export type IconProps = Omit<IcoMoonProps, 'color' | 'icon'> &
  MUIStyledCommonProps<Theme> & { icon: IconNames | keyof typeof icons };

const StyledIcon = styled(IcoMoon)({
  width: 20,
  height: 20,
});

export default function Icon(props: IconProps) {
  if (
    props.icon in icons &&
    icons[props.icon as keyof typeof icons].includes(cdnUrl)
  ) {
    const src = icons[props.icon as keyof typeof icons];

    return (
      <Avatar
        src={src}
        alt={props.icon}
        sx={[
          {
            width: props.size,
            height: props.size,
            userSelect: 'none',
          },
          ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
        ]}
      />
    );
  }

  return (
    <StyledIcon
      iconSet={iconSet}
      {...props}
      icon={
        props.icon in icons
          ? icons[props.icon as keyof typeof icons]
          : props.icon
      }
    />
  );
}
