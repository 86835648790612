import { useState } from 'react';

export function useMenuAnchor() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  function handleAnchorClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleAnchorClose() {
    setAnchorEl(null);
  }

  return { anchorEl, open, handleAnchorClick, handleAnchorClose };
}
