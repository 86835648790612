import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Menu, MenuProps, styled } from '@mui/material';
import { useState } from 'react';

import { AppearOnlineDict } from '@/api/company';
import { useMenuAnchor } from '@/hooks/useMenuAnchor';
import { useMyProfile } from '@/hooks/useMyProfile';
import { getInitials } from '@/utils/avatar';

import Icon from '../Icon';
import StaffAvatar from '../StaffAvatar';
import HelpCenterPanel from './HelpCenterPanel';
import MainPanel from './MainPanel';
import SwitchLanguagePanel from './SwitchLanguagePanel';
import isAccountAppearOnline from './helpers/isAccountAppearOnline';

export enum AccountSettingsPanel {
  MAIN = 'MAIN',
  LANGUAGE = 'LANGUAGE',
  HELP_CENTER = 'HELP_CENTER',
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    transitionDuration={250}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    width: '280px',
    backgroundColor: 'white',
    boxShadow: theme.shadows[3],
    marginTop: '20px',
  },
}));

export default function AccountSettingsMenu() {
  const { logout } = useAuth0();
  const user = useMyProfile();

  const [selectedTab, setSelectedTab] = useState<AccountSettingsPanel>(
    AccountSettingsPanel.MAIN,
  );
  const { anchorEl, open, handleAnchorClick, handleAnchorClose } =
    useMenuAnchor();

  function goBack() {
    setSelectedTab(AccountSettingsPanel.MAIN);
  }

  if (!user.data) {
    return null;
  }
  const initials = getInitials(user.data.userInfo.displayName ?? '');
  const userId = user.data.userInfo.id;
  return (
    <Box>
      <Button
        variant="text"
        id="settings-menu-button"
        aria-controls={open ? 'settings-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleAnchorClick}
        endIcon={<Icon icon="chevron-down" size={12} />}
        sx={{
          borderRadius: '8px',
          padding: '8px',
          '.MuiButton-endIcon': { ml: '4px' },
        }}
      >
        <Box
          component="div"
          width="8px"
          height="8px"
          sx={{
            backgroundColor: isAccountAppearOnline(
              user.data.status ?? AppearOnlineDict.Away,
            )
              ? 'forest.60'
              : 'gray.80',
            borderRadius: '8px',
          }}
        />
        {/* TODO: refactor to custom Avatar cmp with size variant control */}
        <StaffAvatar
          userId={userId}
          optionalSrc={initials}
          sx={{
            width: '24px',
            height: '24px',
            fontSize: '12px',
            mx: '8px',
          }}
        />

        {user.data.userInfo.displayName}
      </Button>
      <StyledMenu
        id="settings-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleAnchorClose}
        MenuListProps={{
          'aria-labelledby': 'settings-menu-button',
        }}
      >
        {selectedTab === AccountSettingsPanel.MAIN && (
          <MainPanel
            name={user.data.userInfo.displayName}
            email={user.data.userInfo.email}
            userInitials={initials}
            goTo={setSelectedTab}
            logout={logout}
          />
        )}
        {selectedTab === AccountSettingsPanel.LANGUAGE && (
          <SwitchLanguagePanel goBack={goBack} />
        )}
        {selectedTab === AccountSettingsPanel.HELP_CENTER && (
          <HelpCenterPanel goBack={goBack} />
        )}
      </StyledMenu>
    </Box>
  );
}
