import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Link,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { Fragment, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import useAccessRuleGuard from '@/pages/Contacts/shared/accessRuleGuard/useAccessRuleGuard';
import {
  isFreeOrFreemiumPlan,
  isProPlan,
} from '@/pages/Settings/helpers/PlanSelectionType';

import CheckListItem from './CheckListItem';
import useGetCheckListData from './hooks/useGetCheckListData';

export default function CheckList(props: { onClose: () => void }) {
  const { onClose } = props;
  const { t } = useTranslation();

  return (
    <ClickAwayListener onClickAway={onClose}>
      <List
        sx={(theme) => ({
          position: 'absolute',
          top: '48px',
          backgroundColor: 'white',
          width: '300px',
          zIndex: 101,
          boxShadow: theme.shadows[2],
          borderRadius: '8px',
          padding: '0',
        })}
      >
        <Suspense
          fallback={
            <Stack flex={1} justifyContent="center" alignItems="center">
              <CircularProgress sx={{ mb: '8px' }} />
              <Typography variant="body2">{t('general.loading')}</Typography>
            </Stack>
          }
        >
          <ListContent />
        </Suspense>
      </List>
    </ClickAwayListener>
  );
}

const ListContent = () => {
  const { t } = useTranslation();
  const accessRulesGuard = useAccessRuleGuard({ suspense: true });
  const currentPlan =
    accessRulesGuard.company.data?.currentPlan.billRecord?.subscriptionPlan;
  const isFreePlan = Boolean(currentPlan && isFreeOrFreemiumPlan(currentPlan));
  const isProPlanCheck = Boolean(currentPlan && isProPlan(currentPlan));
  const {
    channelsInfo,
    inviteUsersInfo,
    upgradePlanInfo,
    manageSubscriptionInfo,
    createTeamInfo,
  } = useGetCheckListData();
  const listItems = isFreePlan
    ? [channelsInfo, inviteUsersInfo, upgradePlanInfo]
    : isProPlanCheck
    ? [channelsInfo, inviteUsersInfo, manageSubscriptionInfo]
    : [channelsInfo, inviteUsersInfo, createTeamInfo, manageSubscriptionInfo];

  return (
    <>
      <ListItem
        sx={{
          borderBottom: '1px solid grey.30',
          padding: '10px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{ color: 'darkblue.70', fontWeight: '600', fontSize: '16px' }}
        >
          {t('setupGuide.list.title', {
            defaultValue: 'Setup guides',
          })}
        </Typography>
      </ListItem>
      <Divider />
      {listItems.map((item, index) => (
        <Fragment key={index}>
          <CheckListItem
            index={index}
            title={item.title}
            description={item.description}
            buttonText={item.buttonText}
            redirectUrl={item.redirectUrl}
          />
          <Divider />
        </Fragment>
      ))}
      {isFreePlan ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            fontSize: '14px',
            padding: '12px',
          }}
        >
          <Box
            sx={{
              marginBottom: '8px',
              color: 'grey.90',
              fontWeight: '400',
              textAlign: 'center',
            }}
          >
            {t('setupGuide.list.schedule', {
              defaultValue:
                'Schedule a personal call tailored for your business needs',
            })}
          </Box>
          <Button
            variant="contained"
            sx={{ marginBottom: '8px', width: '276px' }}
            component={Link}
            underline="none"
            href="https://sleekflow.io/book-a-demo"
            target="_blank"
            rel="noopener"
            aria-label="go-to-book-a-demo"
          >
            {t('setupGuide.list.book-demo', {
              defaultValue: 'Book a demo',
            })}
          </Button>
          <Button
            variant="outlined"
            sx={{ width: '276px' }}
            component={Link}
            underline="none"
            href="https://help.sleekflow.io/"
            target="_blank"
            rel="noopener"
            aria-label="go-to-help-center"
          >
            {t('setupGuide.list.help-center', {
              defaultValue: 'Learn more in Help Center',
            })}
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            color: 'grey.90',
            fontWeight: '600',
            fontSize: '14px',
            padding: '20px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          component={Link}
          underline="none"
          href="https://help.sleekflow.io/"
          target="_blank"
          rel="noopener"
          aria-label="go-to-help-center"
        >
          {t('setupGuide.list.help-center', {
            defaultValue: 'Learn more in Help Center',
          })}
        </Box>
      )}
    </>
  );
};
