import { useRef, useEffect, useContext } from 'react';

import { SignalRContext } from './';

const isNumString = (str: string): boolean => !isNaN(Number(str));

// From https://github.com/sibu-github/deep-parse-json
function deepParseJson(jsonString: any): any {
  // if not stringified json rather a simple string value then JSON.parse will throw error
  // otherwise continue recursion
  if (typeof jsonString === 'string') {
    if (isNumString(jsonString)) {
      // if a numeric string is received, return itself
      // otherwise JSON.parse will convert it to a number
      return jsonString;
    }
    try {
      return deepParseJson(JSON.parse(jsonString));
    } catch (err) {
      return jsonString;
    }
  } else if (Array.isArray(jsonString)) {
    // if an array is received, map over the array and deepParse each value
    return jsonString.map((val: any) => deepParseJson(val));
  } else if (typeof jsonString === 'object' && jsonString !== null) {
    // if an object is received then deepParse each element in the object
    // typeof null returns 'object' too, so we have to eliminate that
    return Object.keys(jsonString).reduce(
      (obj: Record<string, any>, key: string) => {
        const val = jsonString[key];
        obj[key] = deepParseJson(val);
        return obj;
      },
      Object.create(null),
    );
  } else {
    // otherwise return whatever was received
    return jsonString;
  }
}

// Custom hooks
const useSignalREffect = (eventName: string, callback: (args: any) => void) => {
  const callbackRef = useRef<(...args: unknown[]) => void>(callback);
  const signalr = useContext(SignalRContext);

  useEffect(() => {
    const _callback = (...args: any[]) => {
      args[2]
        ? callbackRef.current(deepParseJson(args[2]))
        : callbackRef.current(...args);
    };
    signalr?.subscribe(eventName, _callback);

    return () => {
      signalr?.unsubscribe(eventName, _callback);
    };
  }, [signalr, eventName]);
};

export default useSignalREffect;
