import { Stack } from '@mui/material';
import { ReactNode } from 'react';

import AccountSettingsMenu from './AccountSettingsMenu';
import GlobalSearchBar from './GlobalSearchBar';
import { GuideButton } from './GuideButton';

export default function GlobalTopMenu(props: { children?: ReactNode }) {
  return (
    <Stack
      spacing="20px"
      direction="row"
      sx={{ alignItems: 'center', position: 'relative' }}
    >
      {props.children}
      <GuideButton />
      <GlobalSearchBar />
      <AccountSettingsMenu />
    </Stack>
  );
}
