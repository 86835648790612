import { useTranslation } from 'react-i18next';

import { ROUTES } from '@/constants/navigation';

export default function useGetCheckListData() {
  const { t } = useTranslation();
  const channels = {
    title: t('setupGuide.list.channel.title', {
      defaultValue: 'Connect channels',
    }),
    description: t('setupGuide.list.channel.description', {
      defaultValue:
        'Start connecting your channels to SleekFlow and unlock the power of Inbox',
    }),
    buttonText: t('setupGuide.list.channel.button', {
      defaultValue: 'Go to Channels',
    }),
    redirectUrl: ROUTES.channels,
  };

  const inviteUsers = {
    title: t('setupGuide.list.user.title', {
      defaultValue: 'Invite users',
    }),
    description: t('setupGuide.list.user.description', {
      defaultValue:
        'Add your teammates to SleekFlow and start collaborating on work',
    }),
    buttonText: t('setupGuide.list.user.button', {
      defaultValue: 'Go to user management',
    }),
    redirectUrl: ROUTES.settingsUserManagement,
  };

  const upgradePlan = {
    title: t('setupGuide.list.upgrade.title', {
      defaultValue: 'Upgrade your plan',
    }),
    description: t('setupGuide.list.upgrade.description', {
      defaultValue:
        'Upgrade your plan for advanced features and increased limits',
    }),
    buttonText: t('setupGuide.list.upgrade.button', {
      defaultValue: 'Go to plans and billings',
    }),
    redirectUrl: ROUTES.settingsSubscriptions,
  };

  const manageSubscription = {
    title: t('setupGuide.list.manage-subscription.title', {
      defaultValue: 'Manage subscriptions and add-ons',
    }),
    description: t('setupGuide.list.manage-subscription.description', {
      defaultValue:
        'Change your plan or explore useful add-ons to fulfil your business needs',
    }),
    buttonText: t('setupGuide.list.manage-subscription.button', {
      defaultValue: 'Go to plans and billings',
    }),
    redirectUrl: ROUTES.settingsSubscriptions,
  };

  const createTeam = {
    title: t('setupGuide.list.create-teams.title', {
      defaultValue: 'Manage subscriptions and add-ons',
    }),
    description: t('setupGuide.list.create-teams.description', {
      defaultValue:
        'Change your plan or explore useful add-ons to fulfil your business needs',
    }),
    buttonText: t('setupGuide.list.create-teams.button', {
      defaultValue: 'Go to team management',
    }),
    redirectUrl: ROUTES.settingsTeamManagement,
  };

  return {
    channelsInfo: channels,
    inviteUsersInfo: inviteUsers,
    upgradePlanInfo: upgradePlan,
    manageSubscriptionInfo: manageSubscription,
    createTeamInfo: createTeam,
  };
}
