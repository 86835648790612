import { RoleType } from '@/api/types';
import { useMyProfile } from '@/hooks/useMyProfile';

export const useBroadcastRoleBasedAccessControl = ({
  suspense,
}: { suspense?: boolean } = {}) => {
  const userProfile = useMyProfile({ suspense });

  return {
    // Broadcasts
    canCreateBroadcast: userProfile.data?.roleType !== RoleType.STAFF,
    canViewBroadcasts: userProfile.data?.roleType !== RoleType.STAFF,
    canViewBroadcastReview: userProfile.data?.roleType !== RoleType.STAFF,
    canSendTestBroadcast: userProfile.data?.roleType !== RoleType.STAFF,
    canPublishBroadcast: userProfile.data?.roleType !== RoleType.STAFF,
    canEditDraftBroadcast: userProfile.data?.roleType !== RoleType.STAFF,
  };
};
