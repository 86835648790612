import { useLocation } from 'react-router-dom';

import { useRouteWithLocale } from '@/hooks/useRouteWithLocale/useRouteWithLocale';

export default function useBackToURL({ fallback }: { fallback: string }) {
  const location = useLocation();
  const routeTo = useRouteWithLocale();
  const locationState = location.state as null | { from: string } | object;
  return routeTo(
    locationState && 'from' in locationState ? locationState.from : fallback,
  );
}
