export function getInitials(name: string) {
  const names = name.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

const AVATAR_HUES = [
  { hue: 148, color: 'forest.80' },
  { hue: 178, color: 'emerald.90' },
  { hue: 196, color: 'turquoise.90' },
  { hue: 230, color: 'darkBlue.90' },
  { hue: 238, color: 'purple.80' },
];

export function getAvatarBackgroundColorByName(name: string) {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  const hueHash = hash % 360;

  const colourDiffs = AVATAR_HUES.map((h) => Math.abs(hueHash - h.hue));

  const closestHue = AVATAR_HUES[colourDiffs.indexOf(Math.min(...colourDiffs))];

  return closestHue.color;
}

export function getAvatarUrl(profilePictureURL: string) {
  return profilePictureURL
    ? `${import.meta.env.VITE_API_BASE_URL}${profilePictureURL}`
    : '';
}
