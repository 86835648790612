import { Alert, AlertProps, CircularProgress } from '@mui/material';
import type { SyntheticEvent } from 'react';
import type { Toast } from 'react-hot-toast';
import toast, { Renderable, ToastOptions } from 'react-hot-toast/headless';

export type SnackbarOptions = Pick<AlertProps, 'onClose' | 'action'> &
  Omit<ToastOptions, 'style' | 'position'>;

export type SnackbarFn = (
  content: Renderable,
  options?: SnackbarOptions,
) => string;

export type LoadingSnackbarFn = (
  message: Renderable,
  options: SnackbarOptions & { completed?: boolean },
) => string;

export interface Snackbar {
  info: SnackbarFn;
  error: SnackbarFn;
  warning: SnackbarFn;
  success: SnackbarFn;
  loading: LoadingSnackbarFn;
  dismiss: (id?: string) => void;
}

// A wrapper around react-hot-toast because it doesn't support custom variants
// https://github.com/timolins/react-hot-toast/issues/23
const useSnackbar = (): Snackbar => {
  const _close =
    (t: Toast, onClose?: (event: SyntheticEvent) => void) =>
    (e: SyntheticEvent) => {
      onClose && onClose(e);
      toast.dismiss(t.id);
    };

  const info = (
    message: Renderable,
    options: SnackbarOptions = { duration: 5000 },
  ) => {
    const { action, onClose, ...toastProps } = options;
    return toast(
      (t) => (
        <Alert
          onClose={action === undefined ? _close(t, onClose) : undefined}
          {...{ action }}
          color="info"
        >
          {message}
        </Alert>
      ),
      toastProps,
    );
  };

  const success = (
    message: Renderable,
    options: SnackbarOptions = { duration: 5000 },
  ) => {
    const { action, onClose, ...toastProps } = options;
    return toast(
      (t) => (
        <Alert
          onClose={action === undefined ? _close(t, onClose) : undefined}
          {...{ action }}
          color="success"
        >
          {message}
        </Alert>
      ),
      toastProps,
    );
  };

  const warning = (
    message: Renderable,
    options: SnackbarOptions = { duration: 5000 },
  ) => {
    const { action, onClose, ...toastProps } = options;
    return toast(
      (t) => (
        <Alert
          onClose={action === undefined ? _close(t, onClose) : undefined}
          {...{ action }}
          color="warning"
        >
          {message}
        </Alert>
      ),
      toastProps,
    );
  };

  const error = (
    message: Renderable,
    options: SnackbarOptions = { duration: 5000 },
  ) => {
    const { action, onClose, ...toastProps } = options;
    return toast(
      (t) => (
        <Alert
          onClose={action === undefined ? _close(t, onClose) : undefined}
          {...{ action }}
          color="error"
        >
          {message}
        </Alert>
      ),
      toastProps,
    );
  };

  const loading = (
    message: Renderable,
    options: SnackbarOptions & { completed?: boolean } = {},
  ) => {
    const { action, onClose, completed = false, ...toastProps } = options;
    return toast(
      (t) => (
        <Alert
          onClose={action === undefined ? _close(t, onClose) : undefined}
          action={completed ? null : <CircularProgress color="inherit" />}
          color="info"
        >
          {message}
        </Alert>
      ),
      toastProps,
    );
  };

  const dismiss = (id?: string) => toast.dismiss(id);

  return { info, success, warning, error, loading, dismiss };
};

export default useSnackbar;
