import { wrapCreateBrowserRouter } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouteObject,
  RouterProvider,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useSessionStorage } from 'react-use';

import App from '@/App';
import { CHANNEL } from '@/pages/Broadcasts/constants';
import ContactsIdError from '@/pages/Contacts/ContactsId/ContactsIdError';
import { ContactsImportProvider } from '@/pages/Contacts/ContactsImport/useContactsImport';
import { SignalRProvider } from '@/signalr';

import UnderConstruction from './components/UnderConstruction';
import { AVAILABLE_LANGUAGES } from './constants/i18n';
import { ROUTES } from './constants/navigation';
import AuthenticatedLayout from './layouts/AuthenticatedLayout';
import AuthenticationRequiredLayout from './layouts/AuthenticationRequiredLayout';
import FlowBuilderErrorElement from './pages/FlowBuilder/FlowBuilderErrorElement';
import MobileAppLanding from './pages/MobileAppLanding';
import { checkIsDeviceMobile } from './utils/device';
import { lazyWithRetries } from './utils/lazy-loading';

const AppErrorElement = lazyWithRetries(() => import('./AppErrorElement'));
const Inbox = lazyWithRetries(() => import('./pages/Inbox'));
const FlowBuilder = lazyWithRetries(() => import('./pages/FlowBuilder'));
const FlowBuilderId = lazyWithRetries(
  () => import('./pages/FlowBuilder/FlowBuilderId'),
);
const Contacts = lazyWithRetries(() => import('./pages/Contacts'));
const CreateContact = lazyWithRetries(
  () => import('./pages/Contacts/ContactsCreate'),
);
const ContactsLayout = lazyWithRetries(
  () => import('./pages/Contacts/shared/ContactsLayout'),
);
const ContactsEditColumns = lazyWithRetries(
  () => import('./pages/Contacts/ContactsEditColumns'),
);
const ContactList = lazyWithRetries(
  () => import('./pages/Contacts/ContactsList'),
);
const ContactsListId = lazyWithRetries(
  () => import('./pages/Contacts/ContactsListId'),
);
const ContactsImport = lazyWithRetries(
  () => import('./pages/Contacts/ContactsImport'),
);
const ContactsId = lazyWithRetries(() => import('./pages/Contacts/ContactsId'));
const Broadcasts = lazyWithRetries(() => import('./pages/Broadcasts'));

const BroadcastCreateLine = lazyWithRetries(
  () => import('./pages/Broadcasts/BroadcastCreateChannel/BroadcastCreateLine'),
);
const BroadcastCreateTwilio = lazyWithRetries(
  () =>
    import('./pages/Broadcasts/BroadcastCreateChannel/BroadcastCreateTwilio'),
);
const BroadcastCreateWeChat = lazyWithRetries(
  () =>
    import('./pages/Broadcasts/BroadcastCreateChannel/BroadcastCreateWeChat'),
);
const BroadcastCreateMessenger = lazyWithRetries(
  () =>
    import(
      './pages/Broadcasts/BroadcastCreateChannel/BroadcastFacebookMessenger'
    ),
);
const BroadcastCreateSMS = lazyWithRetries(
  () => import('./pages/Broadcasts/BroadcastCreateChannel/BroadcastCreateSMS'),
);
const BroadcastCreateTelegram = lazyWithRetries(
  () =>
    import('./pages/Broadcasts/BroadcastCreateChannel/BroadcastCreateTelegram'),
);
const BroadcastCreateWhatsApp360Dialog = lazyWithRetries(
  () =>
    import(
      './pages/Broadcasts/BroadcastCreateChannel/BroadcastCreateWhatsApp360Dialog'
    ),
);
const BroadcastCreateWhatsAppCloud = lazyWithRetries(
  () =>
    import(
      './pages/Broadcasts/BroadcastCreateChannel/BroadcastCreateWhatsappCloud'
    ),
);
const BroadcastCreateNote = lazyWithRetries(
  () => import('./pages/Broadcasts/BroadcastCreateChannel/BroadcastCreateNote'),
);
const BroadcastCreateViber = lazyWithRetries(
  () =>
    import('./pages/Broadcasts/BroadcastCreateChannel/BroadcastCreateViber'),
);
const BroadcastReviewLine = lazyWithRetries(
  () => import('./pages/Broadcasts/BroadcastReviewChannel/BroadcastReviewLine'),
);
const BroadcastReviewFacebookMessenger = lazyWithRetries(
  () =>
    import(
      './pages/Broadcasts/BroadcastReviewChannel/BroadcastReviewFacebookMessenger'
    ),
);
const BroadcastReviewTelegram = lazyWithRetries(
  () =>
    import('./pages/Broadcasts/BroadcastReviewChannel/BroadcastReviewTelegram'),
);

const BroadcastReviewWeChat = lazyWithRetries(
  () =>
    import('./pages/Broadcasts/BroadcastReviewChannel/BroadcastReviewWeChat'),
);

const BroadcastReviewViber = lazyWithRetries(
  () =>
    import('./pages/Broadcasts/BroadcastReviewChannel/BroadcastReviewViber'),
);
const BroadcastReviewWhatsAppTwilio = lazyWithRetries(
  () =>
    import(
      './pages/Broadcasts/BroadcastReviewChannel/BroadcastReviewWhatsAppTwilio'
    ),
);
const BroadcastReviewNote = lazyWithRetries(
  () => import('./pages/Broadcasts/BroadcastReviewChannel/BroadcastReviewNote'),
);
const BroadcastReviewSMS = lazyWithRetries(
  () => import('./pages/Broadcasts/BroadcastReviewChannel/BroadcastReviewSMS'),
);
const BroadcastReviewWhatsApp360Dialog = lazyWithRetries(
  () =>
    import(
      './pages/Broadcasts/BroadcastReviewChannel/BroadcastReviewWhatsApp360Dialog'
    ),
);
const BroadcastReviewWhatsAppCloudApi = lazyWithRetries(
  () =>
    import(
      './pages/Broadcasts/BroadcastReviewChannel/BroadcastReviewWhatsAppCloudApi'
    ),
);
const ChannelLayout = lazyWithRetries(
  () => import('./pages/Channels/ChannelLayout'),
);
const Channels = lazyWithRetries(() => import('./pages/Channels'));
const WhatsApp = lazyWithRetries(() => import('./pages/Channels/Whatsapp'));
const Messenger = lazyWithRetries(() => import('./pages/Channels/Messenger'));
const SMS = lazyWithRetries(() => import('./pages/Channels/SMS'));
const WeChat = lazyWithRetries(() => import('./pages/Channels/WeChat'));
const Line = lazyWithRetries(() => import('./pages/Channels/Line'));
const Telegram = lazyWithRetries(() => import('./pages/Channels/Telegram'));
const Viber = lazyWithRetries(() => import('./pages/Channels/Viber'));
const Instagram = lazyWithRetries(() => import('./pages/Channels/Instagram'));

const ConnectWeChat = lazyWithRetries(
  () => import('./pages/Channels/ConnectNewChannel/ConnectWeChat'),
);
const ConnectInstagram = lazyWithRetries(
  () => import('./pages/Channels/ConnectNewChannel/ConnectInstagram'),
);
const InstagramConnectRedirect = lazyWithRetries(
  () => import('./pages/Channels/ConnectNewChannel/InstagramConnectRedirect'),
);
const ConnectLine = lazyWithRetries(
  () => import('./pages/Channels/ConnectNewChannel/ConnectLine'),
);
const ConnectTelegram = lazyWithRetries(
  () => import('./pages/Channels/ConnectNewChannel/ConnectTelegram'),
);
const ConnectSMS = lazyWithRetries(
  () => import('./pages/Channels/ConnectNewChannel/ConnectSMS'),
);
const ConnectViber = lazyWithRetries(
  () => import('./pages/Channels/ConnectNewChannel/ConnectViber'),
);
const ConnectWhatsappCloudApi = lazyWithRetries(
  () => import('./pages/Channels/Whatsapp/ConnectChannel'),
);

const ConnectFacebook = lazyWithRetries(
  () => import('./pages/Channels/ConnectNewChannel/ConnectFacebook'),
);
const FacebookConnectRedirect = lazyWithRetries(
  () => import('./pages/Channels/ConnectNewChannel/FacebookConnectRedirect'),
);

const ContactsShopify = lazyWithRetries(
  () => import('./pages/Contacts/ContactsShopify'),
);

const ContactsMyContacts = lazyWithRetries(
  () => import('./pages/Contacts/ContactsMyContacts'),
);

const ContactsTeam = lazyWithRetries(
  () => import('./pages/Contacts/ContactsTeam'),
);
const NotFound = lazyWithRetries(() => import('./pages/NotFound'));

const SettingsLayout = lazyWithRetries(
  () => import('./pages/Settings/shared/SettingsLayout'),
);
const SettingsGeneral = lazyWithRetries(
  () => import('./pages/Settings/SettingsGeneral'),
);
const SettingsCompanyDetail = lazyWithRetries(
  () => import('./pages/Settings/SettingsCompanyDetail'),
);
const SettingsCompanyDetail2FA = lazyWithRetries(
  () => import('./pages/Settings/SettingsCompanyDetail/SecurityTab/2FA'),
);
const SettingsCustomObject = lazyWithRetries(
  () => import('./pages/Settings/SettingsCustomObject'),
);
const SettingsCompanyDetailIpWhiteList = lazyWithRetries(
  () =>
    import('./pages/Settings/SettingsCompanyDetail/SecurityTab/IpWhiteList'),
);

const AiSettings = lazyWithRetries(() => import('./pages/AiSettings'));

const isMobile = checkIsDeviceMobile();

const stringifiedAvailableLocales = AVAILABLE_LANGUAGES.map(
  (locale) => `^${locale}$`,
).join('|');

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export default function AppRoutes() {
  return <RouterProvider router={router} />;
}

const CheckIsMobileLayout = () => {
  const [viewAsMobile] = useSessionStorage('viewAsMobile', false);

  return !isMobile || viewAsMobile ? <Outlet /> : <MobileAppLanding />;
};

// Invalid locales will be redirected the base route, and eventually the default locale if the route exists
// Example: /fr/inbox -> /inbox -> /en/inbox
const LocalisedRoutesLayout = () => {
  const { locale } = useParams();
  const location = useLocation();

  const isValidLocale = (locale || '').match(
    new RegExp(stringifiedAvailableLocales, 'i'),
  );

  return isValidLocale ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: location.pathname.replace(`/${locale}`, ''),
      }}
      replace
    />
  );
};

// Example: /en -> /en/inbox
const LocalisedBaseRedirect = () => {
  const { i18n } = useTranslation();
  const location = useLocation();

  return (
    <Navigate
      to={{ ...location, pathname: `/${i18n.language}/inbox` }}
      replace
    />
  );
};

// Example: /inbox -> /en/inbox
function BaseRedirect() {
  const { i18n } = useTranslation();
  const location = useLocation();

  return (
    <Navigate
      to={{ ...location, pathname: `/${i18n.language}${location.pathname}` }}
      replace
    />
  );
}

// Example: / -> /en/inbox
function Root() {
  const { i18n } = useTranslation();

  return <Navigate to={{ pathname: `/${i18n.language}/inbox` }} replace />;
}

const ROUTE_CONFIGS: RouteObject[] = [
  {
    path: ROUTES.inbox,
    element: <Inbox />,
  },
  {
    path: ROUTES.broadcasts,
    children: [
      {
        element: <Broadcasts />,
        index: true,
      },
      {
        children: [
          {
            path: CHANNEL.whatsappTwilio,
            children: [
              {
                index: true,
                element: <BroadcastCreateTwilio />,
              },
              {
                path: ':templateId/draft',
                element: <BroadcastCreateTwilio />,
              },
              {
                path: ':templateId/review',
                element: <BroadcastReviewWhatsAppTwilio />,
              },
            ],
          },
          {
            path: CHANNEL.whatsapp360Dialog,
            children: [
              {
                index: true,
                element: <BroadcastCreateWhatsApp360Dialog />,
              },
              {
                path: ':templateId/draft',
                element: <BroadcastCreateWhatsApp360Dialog />,
              },
              {
                path: ':templateId/review',
                element: <BroadcastReviewWhatsApp360Dialog />,
              },
            ],
          },
          {
            path: CHANNEL.whatsappCloudApi,
            children: [
              {
                index: true,
                element: <BroadcastCreateWhatsAppCloud />,
              },
              {
                path: ':templateId/draft',
                element: <BroadcastCreateWhatsAppCloud />,
              },
              {
                path: ':templateId/review',
                element: <BroadcastReviewWhatsAppCloudApi />,
              },
            ],
          },
          {
            path: CHANNEL.facebook,
            children: [
              {
                index: true,
                element: <BroadcastCreateMessenger />,
              },
              {
                path: ':templateId/draft',
                element: <BroadcastCreateMessenger />,
              },
              {
                path: ':templateId/review',
                element: <BroadcastReviewFacebookMessenger />,
              },
            ],
          },
          {
            path: CHANNEL.wechat,
            children: [
              {
                index: true,
                element: <BroadcastCreateWeChat />,
              },
              {
                path: ':templateId/draft',
                element: <BroadcastCreateWeChat />,
              },
              {
                path: ':templateId/review',
                element: <BroadcastReviewWeChat />,
              },
            ],
          },
          {
            path: CHANNEL.sms,
            children: [
              {
                index: true,
                element: <BroadcastCreateSMS />,
              },
              {
                path: ':templateId/draft',
                element: <BroadcastCreateSMS />,
              },
              {
                path: ':templateId/review',
                element: <BroadcastReviewSMS />,
              },
            ],
          },
          {
            path: CHANNEL.line,
            children: [
              {
                index: true,
                element: <BroadcastCreateLine />,
              },
              {
                path: ':templateId/draft',
                element: <BroadcastCreateLine />,
              },
              {
                path: ':templateId/review',
                element: <BroadcastReviewLine />,
              },
            ],
          },
          {
            path: CHANNEL.note,
            children: [
              {
                index: true,
                element: <BroadcastCreateNote />,
              },
              {
                path: ':templateId/draft',
                element: <BroadcastCreateNote />,
              },
              {
                path: ':templateId/review',
                element: <BroadcastReviewNote />,
              },
            ],
          },
          {
            path: CHANNEL.viber,
            children: [
              {
                index: true,
                element: <BroadcastCreateViber />,
              },
              {
                path: ':templateId/draft',
                element: <BroadcastCreateViber />,
              },
              {
                path: ':templateId/review',
                element: <BroadcastReviewViber />,
              },
            ],
          },
          {
            path: CHANNEL.telegram,
            children: [
              {
                index: true,
                element: <BroadcastCreateTelegram />,
              },
              {
                path: ':templateId/draft',
                element: <BroadcastCreateTelegram />,
              },
              {
                path: ':templateId/review',
                element: <BroadcastReviewTelegram />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: ROUTES.contacts,
    children: [
      {
        element: <ContactsLayout />,
        children: [
          {
            index: true,
            element: <Contacts />,
          },
          {
            path: 'shopify',
            element: <ContactsShopify />,
          },
          {
            path: 'my-contacts',
            element: <ContactsMyContacts />,
          },
          {
            path: 'team',
            element: <ContactsTeam />,
          },
          {
            path: 'list/:id',
            element: <ContactsListId />,
          },
        ],
      },
      {
        path: ':id',
        errorElement: <ContactsIdError />,
        children: [
          {
            index: true,
            element: <ContactsId />,
          },
          {
            path: ':tab',
            element: <ContactsId />,
          },
        ],
      },
      {
        path: 'list',
        element: <ContactList />,
      },
      {
        path: 'create',
        element: <CreateContact />,
      },
      {
        path: 'import',
        element: (
          <ContactsImportProvider>
            <ContactsImport />
          </ContactsImportProvider>
        ),
      },
      {
        path: 'edit-columns',
        element: <ContactsEditColumns />,
      },
    ],
  },
  {
    path: ROUTES.flowBuilder,
    errorElement: <FlowBuilderErrorElement />,
    children: [
      {
        index: true,
        element: <FlowBuilder />,
      },
      {
        path: ':id',
        element: <FlowBuilderId />,
      },
    ],
  },
  {
    path: ROUTES.analytics,
    element: <UnderConstruction title="analytics" />,
  },
  {
    path: ROUTES.channels,
    children: [
      {
        element: <ChannelLayout />,
        children: [
          {
            index: true,
            element: <Channels />,
          },
          {
            path: 'whatsapp',
            element: <WhatsApp />,
          },
          {
            path: 'messenger',
            element: <Messenger />,
          },
          {
            path: 'sms',
            element: <SMS />,
          },
          {
            path: 'wechat',
            element: <WeChat />,
          },
          {
            path: 'line',
            element: <Line />,
          },
          {
            path: 'telegram',
            element: <Telegram />,
          },
          {
            path: 'viber',
            element: <Viber />,
          },
          {
            path: 'instagram',
            element: <Instagram />,
          },
        ],
      },
      {
        path: 'wechat/connect',
        element: <ConnectWeChat />,
      },
      {
        path: 'facebook/connect',
        element: <ConnectFacebook />,
      },
      {
        path: 'instagram/connect',
        element: <ConnectInstagram />,
      },
      { path: 'line/connect', element: <ConnectLine /> },
      {
        path: 'telegram/connect',
        element: <ConnectTelegram />,
      },
      {
        path: 'sms/connect',
        element: <ConnectSMS />,
      },
      {
        path: 'viber/connect',
        element: <ConnectViber />,
      },
      {
        path: 'whatsapp-cloud-api/connect',
        element: <ConnectWhatsappCloudApi />,
      },
    ],
  },
  {
    path: ROUTES.integrations,
    element: <UnderConstruction title="integrations" />,
  },
  {
    path: ROUTES.commerceHub,
    element: <UnderConstruction title="commerce-hub" />,
  },
  {
    path: ROUTES.invite,
    element: <UnderConstruction title="invite-users" />,
  },
  {
    path: ROUTES.gettingStarted,
    element: <h1>Getting Started</h1>,
  },
  {
    path: ROUTES.settings,
    children: [
      {
        element: <SettingsLayout />,
        children: [
          {
            index: true,
            element: <SettingsGeneral />,
          },
          {
            path: 'company-details',
            element:
              import.meta.env.VITE_USER_NODE_ENV === 'uat' ? (
                <SettingsCompanyDetail />
              ) : (
                <UnderConstruction title="settings" hiddenHeader />
              ),
          },
          {
            path: 'user-management',
            element: <UnderConstruction title="invite-users" hiddenHeader />,
          },
          {
            path: 'team-management',
            element: <UnderConstruction title="team-management" hiddenHeader />,
          },
          {
            path: 'subscriptions',
            element: <UnderConstruction title="subscription" hiddenHeader />,
          },
          {
            path: 'inbox-settings',
            element: <UnderConstruction title="inbox-settings" hiddenHeader />,
          },
          {
            path: 'custom-objects',
            element:
              import.meta.env.VITE_USER_NODE_ENV === 'uat' ? (
                <SettingsCustomObject />
              ) : (
                <UnderConstruction hiddenHeader={true} title="custom object" />
              ),
          },
        ],
      },
      {
        path: 'company-details/2FA',
        element:
          import.meta.env.VITE_USER_NODE_ENV === 'uat' ? (
            <SettingsCompanyDetail2FA />
          ) : (
            <UnderConstruction title="settings" />
          ),
      },
      {
        path: 'company-details/ip-whiteList',
        element:
          import.meta.env.VITE_USER_NODE_ENV === 'uat' ? (
            <SettingsCompanyDetailIpWhiteList />
          ) : (
            <UnderConstruction title="settings" />
          ),
      },
    ],
  },
  ...(import.meta.env.VITE_USER_NODE_ENV === 'uat'
    ? [
        {
          path: ROUTES.aiSettings,
          children: [
            {
              index: true,
              element: <AiSettings />,
            },
          ],
        },
      ]
    : []),
];

/**
 * The route structure is as follows:
 * 1. Check if user device is mobile, blocks the app entirely if so
 * 2. Check if user is authenticated
 * 3. Check path and handle redirects, for example:
 *  - / -> /en/inbox
 *  - /inbox -> /en/inbox
 *  - /fr -> / -> /en/inbox
 *  - /en -> /en/inbox
 * 4. Exact routes with locale
 * 5. 404
 */
const ROUTER_SETUP: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: <CheckIsMobileLayout />,
        children: [
          {
            element: <AuthenticationRequiredLayout />,
            children: [
              {
                element: <SignalRProvider />,
                children: [
                  {
                    children: [
                      {
                        index: true,
                        element: <Root />,
                      },
                      ...ROUTE_CONFIGS.map((route) => ({
                        // Redirect base route to localised route
                        element: <BaseRedirect />,
                        children: [route],
                      })),
                      ...AVAILABLE_LANGUAGES.map((locale) => ({
                        // Redirect base lang route to inbox
                        element: <LocalisedBaseRedirect />,
                        path: locale,
                      })),
                      ...ROUTE_CONFIGS.map((route) => ({
                        // Localised routes
                        element: <LocalisedRoutesLayout />,
                        path: '/:locale',
                        children: [
                          {
                            element: <AuthenticatedLayout />,
                            children: [
                              {
                                // Allow this to be overwritten
                                errorElement: <AppErrorElement />,
                                children: [route],
                              },
                            ],
                          },
                        ],
                      })),
                      // TODO: redirect page add local to path

                      {
                        path: ROUTES.instagramRedirect,
                        element: <InstagramConnectRedirect />,
                      },
                      {
                        path: ROUTES.facebookRedirect,
                        element: <FacebookConnectRedirect />,
                      },
                      {
                        path: '*',
                        element: <NotFound />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const router = sentryCreateBrowserRouter(ROUTER_SETUP);
